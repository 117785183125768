import { useCallback, useRef, useState } from 'react';

import cn from 'classnames';

import UIResource from '@/components/UIResource';
import { useAppSelector } from '@/store';
import { MerchandisingBanner } from '@/widgets/Promo/MerchandisingBanner';

import { selectHeadlineBackground } from '../QuickSearchCards/model/selectors';
import { useIsPromoActive } from '../lib/useIsPromoActive';

import classes from './Headline.module.scss';

type Props = {
  children: React.ReactNode;
};

export const Headline = ({ children }: Props) => {
  const background = encodeURIComponent(useAppSelector(selectHeadlineBackground));
  const isBookingHeaderActive = useIsPromoActive('bookingHeader');

  const observer = useRef<ResizeObserver | null>(null);
  const [wrapped, setWrapped] = useState(false);
  const headingResizer = useCallback((el: HTMLDivElement | null) => {
    if (el == null) {
      return observer.current?.disconnect();
    }

    const syncIsWrapped = () => {
      const childrenSumWidth =
        [...el.children].reduce((acc, e) => acc + e.clientWidth, 0) +
        parseInt(getComputedStyle(el).getPropertyValue('gap'));
      setWrapped(childrenSumWidth > el.clientWidth);
    };
    syncIsWrapped();

    observer.current = new ResizeObserver(syncIsWrapped);
    observer.current.observe(el);
  }, []);

  if (!isBookingHeaderActive) {
    return children;
  }

  return (
    <div className={classes.root} style={{ backgroundImage: `url("data:image/svg+xml,${background}")` }}>
      <div className={cn(classes.heading, wrapped && classes.wrapped)} ref={headingResizer}>
        <div className={classes.underlineWrapper}>
          <h2 className={classes.title}>
            <UIResource id="Promotion.pageTitle" />
          </h2>
        </div>
        <MerchandisingBanner type="countdownClock" />
      </div>

      {children}
    </div>
  );
};
