import { usePathname } from 'next/navigation';
import { useEffect, useRef } from 'react';

import { useOnRouteChange } from '@/ducks/routeChange';
import { useAppDispatch } from '@/store';
import { type TOptional } from '@/types/common';

import { clearWarning } from '../actions';
import { WarningTarget } from '../types';

const useClearWarningOnAway = (target: WarningTarget, path?: string) => {
  const dispatch = useAppDispatch();
  const currentPath = usePathname()!;
  const initialPathRef = useRef<string>();
  const isActiveRef = useRef<TOptional<boolean>>(true);

  if (!initialPathRef.current) initialPathRef.current = currentPath;

  useEffect(
    () => () => {
      dispatch(clearWarning(target));
      isActiveRef.current = undefined;
    },
    [],
  );

  useOnRouteChange(({ isPathChanged, prevPath }) => {
    const targetPath = path || initialPathRef.current;
    if (isActiveRef.current && isPathChanged && targetPath && prevPath === targetPath) dispatch(clearWarning(target));
  });
};

export default useClearWarningOnAway;
