import PropTypes from 'prop-types';
import React, { useCallback, useContext, useEffect, useMemo, useRef, useState } from 'react';

import cn from 'classnames';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { voyageSearchCabinExhaustErrorSetAction, voyageSearchVoyageExhaustErrorSetAction } from '@/actions';
import AccessKeyCard from '@/components/AccessKeyCard';
import AdvancedFilterRefinement from '@/components/AdvancedFilterRefinement';
import AdvancedSelection from '@/components/ChooseVoyage/AdvancedSelection';
import CurrencyDropdown from '@/components/ChooseVoyage/CurrencyDropdown';
import MainFilter from '@/components/ChooseVoyage/MainFilter';
import PackageCardDetails from '@/components/ChooseVoyage/PackageCardDetails';
import MNVVOfferBanner from '@/components/MNVVOfferBanner';
import PromoBannerPlace, { PromoBannerPlaceId } from '@/components/PromoBanner/Place';
import useRefinementFiltersData from '@/components/ResultRefinements/RefinementPopover/useRefinementFiltersData';
import UIResource from '@/components/UIResource';
import WarningBox, { WarningTarget } from '@/components/WarningBox';
import WarningBoxView from '@/components/WarningBox/View';
import WhatsIncluded from '@/components/WhatsIncluded';
import { selectPriceType } from '@/ducks/filters/selectors';
import { selectIsMultipleVoyageFilterActive } from '@/ducks/pages/chooseVoyage/selectors';
import useBlackFridayIsEnabled from '@/ducks/programs/blackFriday/hooks/useBlackFridayIsEnabled';
import useClearWarningOnAway from '@/features/warnings/hooks/useClearWarningOnAway';
import { WarningType } from '@/features/warnings/types';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import withSideqikScriptForBookingFlow from '@/helpers/hoc/utility/withSideqikScriptForBookingFlow';
import { useGetIsTaxIncluded } from '@/helpers/pricing/lib/tax';
import switchListItem from '@/helpers/switchListItem';
import useOpenable from '@/hooks/useOpenable';
import tagmanager from '@/tagmanager';
import { WHATS_INCLUDED_KEYS } from '@/types/vwo';
import { PromoRotunda } from '@/widgets/Promo/Rotunda';

import FilterViewForSM from './FilterViewForSM';
import SelectedFiltersLabelView from './SelectedFiltersLabelView';

import '../PackageResults.scss';

const PackageItineraryResults = ({
  actions,
  cabinExhaustError,
  headlineWithAdvertCardList,
  isMultipleVoyageFilter,
  mobileSearchAgainClickedFlag,
  priceType,
  sailingsCount,
  voyageExhaustError,
}) => {
  const { formatMessage } = useContext(FormattedMessageContext);
  const { close: closeFilterModal, isOpened: isOpenedFilterModal, open: openFilterModal } = useOpenable(false);
  const [expandedSections, setExpandedSections] = useState([]);
  const [toggleMobileSearchAgain, setToggleMobileSearchAgain] = useState(false);
  const [showCabinExhaustError, setShowCabinExhaustError] = useState(false);
  const [showVoyageExhaustError, setVoyageExhaustError] = useState(false);
  const isBlackFridayEnabled = useBlackFridayIsEnabled();
  const isTaxIncluded = useGetIsTaxIncluded();

  useClearWarningOnAway(WarningTarget.chooseVoyage);

  const onCloseRefinementFilters = useCallback(
    (isOpen, { closedByBackButton }) => {
      if (!isOpen && isOpenedFilterModal && !closedByBackButton) {
        closeFilterModal();
      }
    },
    [closeFilterModal, isOpenedFilterModal],
  );

  const refinementFiltersData = useRefinementFiltersData({
    onChangeStatus: onCloseRefinementFilters,
  });

  useEffect(() => {
    if (cabinExhaustError) {
      actions.voyageSearchCabinExhaustErrorSet(false);
      setShowCabinExhaustError(true);
    }
    if (voyageExhaustError) {
      actions.voyageSearchVoyageExhaustErrorSet(false);
      setVoyageExhaustError(true);
    }
  }, [cabinExhaustError, voyageExhaustError]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current) {
      isFirstRender.current = false;
    } else {
      setToggleMobileSearchAgain(true);
    }
  }, [mobileSearchAgainClickedFlag]);

  const expandSection = useCallback(
    (section) => {
      setExpandedSections(switchListItem(expandedSections, section, true));
    },
    [expandedSections],
  );

  const collapseSection = useCallback(
    (section) => {
      setExpandedSections(switchListItem(expandedSections, section, false));
    },
    [expandedSections],
  );

  const onOpenFilterModal = useCallback(
    (section) => {
      if (section && /* not event */ typeof section == 'string') {
        tagmanager.tracker.voyagesFilter.trackShowFilters();
        setExpandedSections(switchListItem(expandedSections, section, true));
      }
      openFilterModal();
    },
    [expandedSections, openFilterModal],
  );

  const onCloseFilterModal = useCallback(() => {
    setToggleMobileSearchAgain(false);
    closeFilterModal();
    setExpandedSections([]);
  }, [closeFilterModal, setToggleMobileSearchAgain]);

  const errorList = useMemo(() => {
    let errors = [];
    if (showCabinExhaustError) {
      errors.push(
        formatMessage({
          defaultMessage: 'We’re sorry, the cabin you’ve selected is unavailable.',
          id: 'CabinExhaust.ChooseVoyage.error',
        }),
      );
    }
    if (showVoyageExhaustError) {
      errors.push(
        formatMessage({
          defaultMessage: 'We’re sorry, the Voyage you’ve selected is unavailable.',
          id: 'VoyageExhaust.ChooseVoyage.error',
        }),
      );
    }
    return errors.length > 1 ? (
      <>
        {errors.map((item, i) => (
          <p key={i}>{item}</p>
        ))}
      </>
    ) : (
      errors[0]
    );
  }, [showCabinExhaustError, showVoyageExhaustError]);

  return (
    <section className={cn('PackageItineraryResults', { _blackFriday: isBlackFridayEnabled })}>
      <CurrencyDropdown />
      {refinementFiltersData.popovers}
      <div className="PackageItineraryResults__header">
        <PromoBannerPlace placeId={PromoBannerPlaceId.itineraryResultsA} />
        <h1>
          <span>
            <UIResource id="ChooseVoyage.Results.heading" values={{ N: sailingsCount }} />
          </span>
          <span className="highlighter" />
        </h1>
        <MainFilter onOpenFilterModal={onOpenFilterModal} refinementFiltersData={refinementFiltersData} />
        <FilterViewForSM
          closeFilterModal={onCloseFilterModal}
          openFilterModal={onOpenFilterModal}
          refinementFiltersData={refinementFiltersData}
        />
        <SelectedFiltersLabelView onOpenFilterModal={onOpenFilterModal} />
        <AdvancedFilterRefinement
          closeFilterModal={onCloseFilterModal}
          collapseSection={collapseSection}
          expandSection={expandSection}
          expandedSections={expandedSections}
          isMultipleVoyageFilter={isMultipleVoyageFilter}
          isOpen={isOpenedFilterModal || toggleMobileSearchAgain}
          refinementFiltersData={refinementFiltersData}
          renderMainFilter={onCloseFilterModal}
        />
        <div className="PackageItineraryResults__header_shadow_container" />
      </div>
      <AccessKeyCard />
      <div className="PackageItineraryResults__content">
        {headlineWithAdvertCardList}

        <div className="PackageItineraryResults__body">
          <WhatsIncluded vwoFeatureVariableKey={WHATS_INCLUDED_KEYS.CHOOSE_VOYAGE_PAGE} />
          {(showCabinExhaustError || showVoyageExhaustError) && (
            /* TODO: It's better to use <WarningBox/> instead with suitable action */
            <WarningBoxView
              id="CabinExhaustChooseVoyageErrors"
              text={errorList}
              title="This page contains errors 🤦"
              type={WarningType.ERROR}
            />
          )}
          <AdvancedSelection />
          <PromoBannerPlace placeId={PromoBannerPlaceId.itineraryResultsB} />
          <WarningBox className="PackageItineraryResults__warningBox" scrollToBox target={WarningTarget.chooseVoyage} />
          <MNVVOfferBanner />
          <PackageCardDetails />
        </div>
      </div>
      <div className="PackageItineraryResults__footer" suppressHydrationWarning>
        <FormattedMessage
          defaultMessage={
            isTaxIncluded
              ? 'Average starting price per sailor per night (incl. taxes & fees)'
              : 'Average starting price per sailor per night (excl. taxes & fees)'
          }
          id={
            isTaxIncluded
              ? `NewChooseVoyage.TaxesFeesIncluded.${priceType}`
              : `NewChooseVoyage.TaxesFeesExcluded.${priceType}`
          }
          values={{ priceType }}
        />
      </div>

      <PromoRotunda />
    </section>
  );
};

PackageItineraryResults.propTypes = {
  actions: PropTypes.shape({
    voyageSearchCabinExhaustErrorSet: PropTypes.func,
  }).isRequired,
  cabinExhaustError: PropTypes.bool.isRequired,
  isMultipleVoyageFilter: PropTypes.bool,
  priceType: PropTypes.string.isRequired,
  sailingsCount: PropTypes.number,
};

function mapStateToProps(state) {
  return {
    cabinExhaustError: state?.voyagePlanner?.itineraryResults?.cabinExhaustError,
    isMultipleVoyageFilter: selectIsMultipleVoyageFilterActive(state),
    priceType: selectPriceType(state),
    sailingsCount: state?.cruisesCommon?.voyagesData?.sailingsCount,

    voyageExhaustError: state?.voyagePlanner?.itineraryResults?.voyageExhaustError,
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        voyageSearchCabinExhaustErrorSet: voyageSearchCabinExhaustErrorSetAction,
        voyageSearchVoyageExhaustErrorSet: voyageSearchVoyageExhaustErrorSetAction,
      },
      dispatch,
    ),
  };
}

export default connect(
  mapStateToProps,
  mapDispatchToProps,
)(withSideqikScriptForBookingFlow(PackageItineraryResults, {}));
