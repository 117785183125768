'use client';

import dynamic from 'next/dynamic';

import { Skeleton } from '@/components/Skeleton';
import { selectMerchandisingBannerDetails } from '@/features/promoBanners/selectors';
import { useAppSelector } from '@/store';

import classes from './Timer.module.scss';

const Countdown = dynamic(() => import('@/components/Countdown'), {
  loading: () => <Skeleton containerClassName={classes.skeleton} rounded />,
  ssr: false,
});

const isCountdownDataValid = (countdown: string | undefined): countdown is string => {
  if (!countdown) {
    return false;
  }
  const endDate = new Date(countdown);
  const currentDate = new Date();
  if (endDate <= currentDate) {
    return false;
  }
  return true;
};

export const PromotionTimer = () => {
  const merchandisingBanner = useAppSelector(selectMerchandisingBannerDetails);

  const countDown = merchandisingBanner?.countdown;

  if (!isCountdownDataValid(countDown)) {
    return null;
  }

  return <Countdown className={classes.countdown} frequency={3} targetTime={countDown} />;
};
