import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import { selectHomePorts } from '@/ducks/filters/selectors';

export const selectHomePortsLabel = createSelector(selectHomePorts, selectResources, (ports, resources) => {
  if (isEmpty(ports)) {
    return combineUIResource(resources, 'AdvancedFilter.Port.Default.value');
  }

  const firstPort = ports[0]!.name;

  if (ports.length === 1) {
    return combineUIResource(resources, 'AdvancedFilter.Port.value', { n: firstPort });
  }

  const secondPort = ports[1]!.name;

  if (ports.length === 2) {
    return combineUIResource(resources, 'AdvancedFilter.Port.two.selected', { m: secondPort, n: firstPort });
  }

  if (ports.length === 3) {
    return combineUIResource(resources, 'AdvancedFilter.Port.three.selected', { l: 1, m: secondPort, n: firstPort });
  }

  return combineUIResource(resources, 'AdvancedFilter.Port.multiple.selected', {
    l: ports.length - 2,
    m: secondPort,
    n: firstPort,
  });
});
