import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import { combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import { selectDurations, selectWeekend } from '@/ducks/filters/selectors';
import { getDurationLabel } from '@/helpers/util/sailing';

export const selectDurationsLabel = createSelector(
  selectDurations,
  selectWeekend,

  selectResources,
  (durations, weekend, resources) => {
    if (weekend && isEmpty(durations)) {
      return combineUIResource(resources, 'AdvancedRefinement.weekendOnlyLabel');
    }

    const durationsLabel = sortBy(durations || [], 'min')
      .map(getDurationLabel)
      .join(', ');

    if (weekend && durationsLabel) {
      return combineUIResource(resources, 'AdvancedRefinement.durationAndWeekendLabel', { durations: durationsLabel });
    }

    if (durationsLabel) {
      return combineUIResource(resources, 'AdvancedRefinement.label', { durations: durationsLabel });
    }

    return combineUIResource(resources, 'AdvancedFilter.Duration.Default.Value');
  },
);

export const selectWeekendLabel = createSelector(selectWeekend, selectResources, (weekend, resources) => {
  return weekend ? combineUIResource(resources, 'AdvancedRefinement.weekendOnlyLabel') : null;
});

export const selectDurationsChipLabel = createSelector(selectDurations, selectResources, (durations, resources) => {
  const label = sortBy(durations || [], 'min')
    .map(getDurationLabel)
    .join(' & ');
  return !isEmpty(label) ? combineUIResource(resources, 'AdvancedRefinement.label', { durations: label }) : null;
});
