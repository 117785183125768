// grid sizes

const breakpoints = {
  lg: 992,
  md: 768,
  sm: 576,
  xl: 1200,
  xs: 0,
  xxl: 1600,
};

export default breakpoints;
