import UIResource from '@/components/UIResource';
import { resetMnvvFlow, resetMnvvSearchCriteria } from '@/ducks/mnvv/reset';
import { useAppDispatch } from '@/store';

import TrueZeroResults from './TrueZeroResults';

const MNVVTrueZeroResults = () => {
  const dispatch = useAppDispatch();

  const resetSearchCriteria = () => {
    dispatch(resetMnvvSearchCriteria());
  };

  const resetFlow = () => {
    dispatch(resetMnvvFlow());
  };

  return (
    <TrueZeroResults
      description={<UIResource id="ChooseVoyage.ZeroResults.MNVVheading" />}
      emoji={<UIResource id="ChooseVoyage.ZeroResults.MNVVemojis" />}
      firstButtonText={<UIResource id="ChooseVoyage.ZeroResults.MNVVprimaryCTA" />}
      onFirstButtonClick={resetSearchCriteria}
      onSecondButtonClick={resetFlow}
      secondButtonText={<UIResource id="ChooseVoyage.ZeroResults.MNVVsecondaryCTA" />}
    />
  );
};

export default MNVVTrueZeroResults;
