import { fetchCMSComponent } from '@/helpers/api/app';

import { useIsPromoActive } from '../lib/useIsPromoActive';
import { useEmbeddedContent } from './hooks/useEmbeddedContent';

const CMS_CONTENT_URL = 'rotunda/0';

const Rotunda = () =>
  useEmbeddedContent({
    className: 'PromoRotunda',
    fetcher: fetchCMSComponent,
    isInline: true,
    noAuth: true,
    url: CMS_CONTENT_URL,
  });

export const PromoRotunda = () => {
  const isActive = useIsPromoActive('rotunda');
  return isActive && <Rotunda />;
};
