import { useContext } from 'react';

import type { VWOFlag } from '@/types/vwo';

import VWOGlobalFeatureContext from '@/contexts/VWOGlobalFeatureContext';

export const useGlobalFeature = (key: VWOFlag) => {
  const map = useContext(VWOGlobalFeatureContext);

  return {
    enabled: !!map[key],
  };
};
