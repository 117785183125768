import { useSearchParams } from 'next/navigation';
import React from 'react';

import { selectHomePortsLabel } from '@/ducks/filters/labels/homePorts';
import { FILTER_KEY } from '@/ducks/filters/types';
import { checkIfFilterOverridden } from '@/ducks/filters/utils';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const ShipFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const label = useAppSelector(selectHomePortsLabel);
  const searchParams = useSearchParams();

  return checkIfFilterOverridden(FILTER_KEY.homePorts, searchParams) ? (
    <SelectedFilterChip filterKeys={FILTER_KEY.homePorts} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default ShipFilterChip;
