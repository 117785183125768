import { createSelector } from '@reduxjs/toolkit';

import type { TCurrencyCode } from '@/infra/types/common';
import type { RootState } from '@/store';

import { selectSailors } from '@/ducks/filters/selectors';

import { getCacheKey } from './utils';

export const selectQuickSearchItemsData = (state: RootState) => state.quickSearchItems;

// TODO: select current currency code from redux when will have correct value

export const selectQuickSearchItems = (currencyCode: TCurrencyCode) =>
  createSelector(
    selectQuickSearchItemsData,
    selectSailors,
    (data, sailors) => data.items[getCacheKey(currencyCode!, sailors)]?.data,
  );

export const selectQuickSearchItemsIsLoaded = (currencyCode: TCurrencyCode) =>
  createSelector(selectQuickSearchItemsData, selectSailors, (data, sailors) => {
    const loadedAt = data.items[getCacheKey(currencyCode!, sailors)]?.loadedAt;
    return loadedAt && loadedAt > 0;
  });

export const selectHeadlineBackground = (state: RootState) => selectQuickSearchItemsData(state).headlineBackground;
