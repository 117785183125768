import PropTypes from 'prop-types';
import React from 'react';

import { getMonth, getYear, lastDayOfMonth } from 'date-fns';
import noop from 'lodash/noop';
import { connect } from 'react-redux';

import DatePicker from '@/components/DatePicker';
import ArrowLeft from '@/components/Icon/ArrowLeft';
import Calendar from '@/components/Icon/Calendar';
import Close from '@/components/Icon/Close';
import UIResource from '@/components/UIResource';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY } from '@/ducks/filters/types';
import { getDefaultFilters, selectDefaultMaxDate, selectDefaultMinDate } from '@/ducks/filtersOptions/selectors';
import { selectMnvvVoyage } from '@/ducks/mnvv/selectors';
import { getMnvvReservation } from '@/ducks/mnvv/utils';
import { selectIsMultipleVoyageFilterActive } from '@/ducks/pages/chooseVoyage/selectors';
import { format, parse } from '@/helpers/util/dateUtil';
import { trackResetFilters } from '@/helpers/util/tracking';
import withSearchParams from '@/hocs/withSearchParams';

import RefinementButtons from '../RefinementButtons';
import RefinementPopover from '../RefinementPopover';

import './DateRefinement.scss';

class DateRefinement extends React.Component {
  getDefaultState = (props) => ({
    reset: false,
    selectedEnd: props.appliedEndDate,
    selectedStart: props.appliedStartDate,
  });

  componentDidUpdate(prevProps) {
    if (this.props.popoverProps && this.props.popoverProps.isOpen !== prevProps.popoverProps?.isOpen) {
      this.setState(this.getDefaultState(this.props));
    }
  }

  onSelectMonth = (start, end) => {
    this.setState({
      reset: false,
      selectedEnd: end,
      selectedStart: start,
    });
  };

  onSubmit = (event) => {
    event.preventDefault();
    const { maxDate, reset, selectedEnd, selectedStart } = this.state;
    const { popoverProps, searchParams } = this.props;
    const { isMNVV } = getMnvvReservation(searchParams);

    const maxTime = parse(maxDate);
    const startTime = parse(selectedStart);
    const endTime = selectedEnd ? parse(selectedEnd) : lastDayOfMonth(startTime);

    const isSameYear = getYear(startTime) === getYear(maxTime) || getYear(startTime) === getYear(maxTime);
    const isSameMonth = getMonth(startTime) === getMonth(maxTime) || getMonth(endTime) === getMonth(maxTime);

    const isMNVVAllowed = isMNVV && isSameMonth && isSameYear;
    const endDate = isMNVVAllowed ? maxDate : selectedEnd || format(endTime);

    popoverProps?.togglePopover?.(event);

    setFilters({
      [FILTER_KEY.dateFrom]: selectedStart,
      [FILTER_KEY.dateTo]: endDate,
      ...(reset ? { [FILTER_KEY.voyageIds]: null } : undefined),
    });
  };

  resetSelectedDates = () => {
    const { defaultEndDate, defaultStartDate } = this.props;
    this.setState({
      reset: true,
      selectedEnd: defaultEndDate,
      selectedStart: defaultStartDate,
    });

    trackResetFilters('date-range');
  };

  isDataApplied = () => {
    const { reset } = this.state;
    const { appliedEndDate, appliedStartDate, defaultEndDate, defaultStartDate, isMultipleVoyageFilter } = this.props;

    if (reset && isMultipleVoyageFilter) {
      return true;
    }

    return defaultStartDate !== appliedStartDate || defaultEndDate !== appliedEndDate;
  };

  isDataChanged = () => {
    const { selectedEnd, selectedStart } = this.state;
    const { appliedEndDate, appliedStartDate } = this.props;

    return selectedStart !== appliedStartDate || selectedEnd !== appliedEndDate;
  };

  onGoBackClick = (event) => {
    this.props.popoverProps?.togglePopover?.(event, { closedByBackButton: true });
  };

  constructor(props) {
    super(props);
    this.state = this.getDefaultState(props);
  }

  render() {
    const { isMobile, maxDate, minDate, popoverProps } = this.props;
    const { selectedEnd, selectedStart } = this.state;

    return (
      <RefinementPopover
        {...popoverProps}
        className="date-refinement-filter-popup DateRefinement -newFilter"
        dialogLabel="Date Options"
        id="DateRefinement"
      >
        <div className="filter-popup__header">
          {isMobile && (
            <>
              <button
                aria-label="Go back"
                className="filter-popup__header__button filter-popup__header__button-back"
                onClick={this.onGoBackClick}
              >
                <ArrowLeft />
              </button>
              <button
                aria-label="Close"
                className="filter-popup__header__button filter-popup__header__button-close"
                onClick={popoverProps.togglePopover}
              >
                <Close />
              </button>
            </>
          )}

          <span className="filter-popup__header__title">
            <Calendar />
            <span className="filter-popup__header__title__title-text">
              <UIResource id="DateRefinement.heading" />
            </span>
          </span>
        </div>

        <DatePicker
          applyNewFilterUI
          isShowHeader={false}
          maxDate={maxDate}
          minDate={minDate}
          onSelectMonth={this.onSelectMonth}
          rangeEnd={selectedEnd}
          rangeStart={selectedStart}
          resetSelectedDates={noop}
        />

        <RefinementButtons
          isDataApplied={this.isDataApplied()}
          isDataChanged={this.isDataChanged()}
          onApply={this.onSubmit}
          onReset={this.resetSelectedDates}
          withBottomGlow={isMobile}
        />
      </RefinementPopover>
    );
  }
}

DateRefinement.propTypes = {
  appliedEndDate: PropTypes.string.isRequired,
  appliedStartDate: PropTypes.string.isRequired,
  defaultEndDate: PropTypes.string.isRequired,
  defaultStartDate: PropTypes.string.isRequired,
  isMobile: PropTypes.bool,
  isMultipleVoyageFilter: PropTypes.bool,
  maxDate: PropTypes.string.isRequired,
  minDate: PropTypes.string.isRequired,
  popoverProps: PropTypes.shape({}),
};

const mapStateToProps = (state, { searchParams }) => {
  const defaultFilters = getDefaultFilters(state);

  return {
    appliedEndDate: selectToDate(state),
    appliedStartDate: selectFromDate(state),
    defaultEndDate: defaultFilters.toDate,
    defaultStartDate: defaultFilters.fromDate,
    isMultipleVoyageFilter: selectIsMultipleVoyageFilterActive(state),
    maxDate: selectDefaultMaxDate(state, searchParams),
    minDate: selectDefaultMinDate(state),
    mnvvVoyage: selectMnvvVoyage(state),
  };
};

export default withSearchParams(connect(mapStateToProps)(DateRefinement));
