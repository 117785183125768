import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/env-provider.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next-runtime-env/build/provider/use-env-context.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/script.js");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/components/BasePage/index.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/containers/VoyagePlanner/PackageResults/DataLoader.tsx");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/src/containers/VoyagePlanner/PackageResults/index.js");
