import React, { type MouseEventHandler } from 'react';

import Icon from '@publicImages/svg/playback-pause-small.svg?icon';

type PauseProps = {
  onClick: MouseEventHandler;
};

const PauseSmall = (props: PauseProps) => <Icon {...props} />;

export default PauseSmall;
