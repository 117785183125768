import PropTypes from 'prop-types';
import React from 'react';

import ReactPlayer from 'react-player';

import './Video.scss';

const propTypes = {
  /** Poster for the video */
  poster: PropTypes.string.isRequired,
  /** URL for the video */
  video: PropTypes.string.isRequired,
};
const defaultProps = {};

const Video = ({ poster, video, videoRef, ...props }) => (
  <div className="VideoContainer">
    <ReactPlayer
      className="VideoContainer__video"
      config={{ file: { attributes: { poster } } }}
      height="100%"
      ref={videoRef}
      url={video}
      width="100%"
      {...props}
    />
  </div>
);

Video.propTypes = propTypes;
Video.defaultProps = defaultProps;

export default Video;
