import { createSelector } from '@reduxjs/toolkit';
import every from 'lodash/every';
import groupBy from 'lodash/groupBy';
import keyBy from 'lodash/keyBy';
import sortBy from 'lodash/sortBy';

import type { Package } from '@/infra/types/voyageInfo/package';

import { selectCruisesCommonVoyagesDataRegions } from '@/ducks/cruisesCommon/selectors';
import {
  selectSelectedItineraries,
  selectSelectedItinerariesRegions,
  selectSelectedPortsOfCall,
  selectSelectedPortsOfCallRegions,
} from '@/ducks/filters/selectors';
import { selectMainPackages } from '@/ducks/pages/chooseVoyage/selectors';

import {
  type DestinationCountryItem,
  type DestinationItineraryItem,
  type DestinationPortOfCallItem,
  DestinationType,
  type DestinationsFilterData,
} from './types';

const selectItinerariesData = createSelector(
  [
    selectMainPackages,
    selectSelectedItinerariesRegions,
    selectSelectedItineraries,
    selectSelectedPortsOfCall,
    selectCruisesCommonVoyagesDataRegions,
  ],
  (mainPackages, selectedRegions, selectedItineraries, selectedPorts, regions) => {
    const mainPackagesByPkgCode = keyBy(mainPackages, 'packageCode');

    return sortBy(
      regions.reduce<DestinationsFilterData[DestinationType.ITINERARIES]>((itinerariesData, region) => {
        let items = sortBy(
          region.packages.reduce<DestinationItineraryItem[]>((items, item) => {
            const pkg = item.id ? (mainPackagesByPkgCode[item.id] as Package) : undefined;

            return item.id
              ? [
                  ...items,
                  {
                    duration: pkg?.duration,
                    id: item.id,
                    image: item.image,
                    label: item.name!,
                    order: item.navigationOrder,
                    selected:
                      selectedItineraries.length === 0 && selectedPorts.length === 0
                        ? selectedRegions.includes(region.id)
                        : selectedItineraries.includes(item.id),
                    startingPrice: pkg?.startingPrice,
                  } as unknown as DestinationItineraryItem,
                ]
              : items;
          }, []),
          ['label', 'id'],
        );

        items = Object.values(groupBy([...new Map(items.map((m) => [m.id, m])).values()], 'label')).map(
          (items) =>
            sortBy(items, ['id']).sort(
              (a, b) => (a.startingPrice?.amount || Number.MAX_VALUE) - (b.startingPrice?.amount || Number.MAX_VALUE),
            )[0]!,
        );

        return [
          ...itinerariesData,
          {
            allItemsIds: items.map(({ id }) => id),
            allSelected: items.length > 0 && items.filter(({ selected }) => selected).length === items.length,
            id: region.id,
            image: region.image,
            items,
            itemsCount: items.length,
            label: region.name,
            order: region.navigationOrder,
            selected: selectedRegions.includes(region.id),
            selectedItemsIds: items.reduce<string[]>((ids, item) => (item.selected ? [...ids, item.id] : ids), []),
            subLabel: region.title,
          },
        ];
      }, []),
      ['order', 'label'],
    );
  },
);

const selectPortsOfCallData = createSelector(
  [selectSelectedPortsOfCallRegions, selectSelectedPortsOfCall, selectCruisesCommonVoyagesDataRegions],
  (selectedRegions, selectedPorts, regions) =>
    sortBy(
      regions.reduce<DestinationsFilterData[DestinationType.PORTS_OF_CALL]>((itinerariesData, region) => {
        const countries = Object.keys(region.portsOfCall)
          .sort()
          .reduce<DestinationCountryItem[]>((items, countryName) => {
            const ports = sortBy(
              region.portsOfCall[countryName]?.reduce<DestinationPortOfCallItem[]>((ports, port) => {
                return [
                  ...ports,
                  {
                    id: port.code,
                    image: port.smallThumbnail,
                    label: port.name,
                    order: port.navigationOrder,
                    selected:
                      selectedPorts.length > 0
                        ? selectedPorts.includes(port.code)
                        : selectedRegions.includes(region.id),
                    subLabel: '',
                  },
                ];
              }, []),
              ['order', 'label'],
            );

            const selectedItemsIds = ports.reduce<string[]>(
              (ids, item) => (item.selected ? [...ids, item.id] : ids),
              [],
            );

            return [
              ...items,
              {
                allItemsIds: ports.map(({ id }) => id),
                allSelected: selectedItemsIds.length === ports.length,
                items: ports,
                label: countryName,
                selectedItemsIds,
              },
            ];
          }, []);

        return [
          ...itinerariesData,
          {
            allItemsIds: countries.reduce<string[]>((allItemsIds, item) => [...allItemsIds, ...item.allItemsIds], []),
            allSelected: every(countries, ({ allSelected }) => allSelected),
            id: region.id,
            image: region.image,
            items: countries,
            itemsCount: countries.reduce((count, item) => count + item.items.length, 0),
            label: region.name,
            order: region.navigationOrder,
            selected: selectedRegions.includes(region.id),
            selectedItemsIds: countries.reduce<string[]>(
              (selectedItemsIds, item) => [...selectedItemsIds, ...item.selectedItemsIds],
              [],
            ),
            subLabel: region.title,
          },
        ];
      }, []),
      'order',
    ),
);

export const selectDestinationType = createSelector(
  [selectSelectedItineraries, selectSelectedPortsOfCall],
  (selectedItineraries, selectedPortsOfCall) =>
    selectedItineraries.length > 0 || selectedPortsOfCall.length === 0
      ? DestinationType.ITINERARIES
      : DestinationType.PORTS_OF_CALL,
);

export const selectDestinationsFilterData = createSelector(
  [selectItinerariesData, selectPortsOfCallData],
  (itinerariesData, portsOfCallData) =>
    ({
      [DestinationType.ITINERARIES]: itinerariesData,
      [DestinationType.PORTS_OF_CALL]: portsOfCallData,
    }) as DestinationsFilterData,
);
