import Image from 'next/image';
import PropTypes from 'prop-types';
import React, { useRef, useState } from 'react';

import classNames from 'classnames';
import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { FadeIn } from '@/components/Animations';
import SizzleReelsControls from '@/components/ChooseVoyage/PackageCardDetails/ImageVideo/SizzleReelsControls';
import { Skeleton } from '@/components/Skeleton';
import Video from '@/components/Video';
import { VWOFlag, useVWOFlag } from '@/hooks/useVWOFeature';

import './ImageVideo.scss';

const ImageVideo = ({ controls, imgSrc, isImageHover, priority, sizzleReelsControls, skeletonProps, videoSrc }) => {
  const isSizzleReelsCampaign = useVWOFlag(VWOFlag.SIZZLE_REELS);
  const videoRef = useRef(null);
  const isPlaying = isImageHover && isSizzleReelsCampaign;
  const isVideoEnabled = videoSrc && isSizzleReelsCampaign;
  const sizzleReelsControlsEnabled = sizzleReelsControls && isSizzleReelsCampaign;
  const controlsEnabled = controls && !sizzleReelsControlsEnabled;
  const [isImageLoaded, setIsImageLoaded] = useState();
  const className = classNames('ImageVideo', { _disableControls: !controlsEnabled });
  const [isVideoReady, setVideoReady] = useState(false);

  const onVideoReady = () => {
    if (isVideoEnabled) {
      setVideoReady(true);
    }
  };

  const isShowImageSkeleton = !isImageLoaded && !isEmpty(skeletonProps);
  return (
    <div className={className}>
      {isShowImageSkeleton && <Skeleton height={skeletonProps.height} width={skeletonProps.width} />}

      {!isVideoReady && (
        <Image
          alt="Picture of the cruise"
          className={cn('ImageVideo__imageItem', { ImageVideo__imageItem_hidden: isShowImageSkeleton })}
          fill
          onLoad={() => setIsImageLoaded(true)}
          priority={priority}
          sizes="100vw, 100vh"
          src={imgSrc}
        />
      )}
      {isVideoEnabled && (
        <FadeIn in mountOnEnter timeout={500} unmountOnExit>
          <Video
            controls={controlsEnabled}
            loop
            muted
            onReady={onVideoReady}
            playing={isPlaying}
            playsinline
            video={videoSrc}
            videoRef={videoRef}
          />
        </FadeIn>
      )}
      {isVideoEnabled && sizzleReelsControlsEnabled && <SizzleReelsControls videoRef={videoRef} />}
    </div>
  );
};
ImageVideo.propTypes = {
  controls: PropTypes.bool,
  imgSrc: PropTypes.string.isRequired,
  isImageHover: PropTypes.bool,
  priority: PropTypes.bool,
  sizzleReelsControls: PropTypes.bool,
  skeletonProps: PropTypes.objectOf(
    PropTypes.shape({
      height: PropTypes.string,
      width: PropTypes.string,
    }),
  ),
  videoSrc: PropTypes.string,
};

ImageVideo.defaultProps = {
  controls: false,
  isImageHover: false,
  priority: false,
  sizzleReelsControls: false,
  skeletonProps: {},
  videoSrc: '',
};
export default ImageVideo;
