import type { FilteredPackage } from '@/infra/types/voyageInfo/package';

import { selectAccessKey } from '@/ducks/accessKeys';
import { useMNVV } from '@/ducks/mnvv/hooks';
import { useAppSelector } from '@/store';

import AKTrueZeroResults from './AKTrueZeroResults';
import DefaultZeroResults from './DefaultZeroResults';
import MNVVTrueZeroResults from './MNVVTrueZeroResults';

import './ZeroResults.scss';

const ZeroResults = ({ renderPackage }: { renderPackage: (e: FilteredPackage) => React.ReactNode }) => {
  const hasAccessKey = !!useAppSelector(selectAccessKey);
  const { isMNVV } = useMNVV();
  return (
    <section className="ZeroResults">
      {hasAccessKey && <AKTrueZeroResults />}
      {!hasAccessKey && isMNVV && <MNVVTrueZeroResults />}
      {!hasAccessKey && !isMNVV && <DefaultZeroResults renderPackage={renderPackage} />}
    </section>
  );
};

export default ZeroResults;
