import Link from 'next/link';
import { useId } from 'react';

import cn from 'classnames';

import SailorCardCalendar from '@publicImages/svg/sailor-card-calendar.svg?icon';

import type { SailingFromPackages } from '@/infra/types/voyageInfo/sailing';

import AlmostSoldOut from '@/components/AlmostSoldOut';
import UIResource from '@/components/UIResource';
import { Price } from '@/components/elements';
import { CABIN_CATEGORY_CODES } from '@/constants/cabinCategoryCodes';
import { selectCabins, selectPriceType, selectSailors } from '@/ducks/filters/selectors';
import useBlackFridayIsEnabled from '@/ducks/programs/blackFriday/hooks/useBlackFridayIsEnabled';
import { getAmountPerItem, getAmountPerVoyage } from '@/helpers/data/mappers/Summary';
import { formatDate } from '@/helpers/util/dateUtil';
import usePrecheckoutUrlWithParams from '@/hooks/usePrecheckoutPath';
import { FiltersPriceType } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import './SailingCard.scss';

type Props = {
  isBestPriceSailing?: boolean;
  onSailingClick?: (sailing: SailingFromPackages) => void;
  sailing: SailingFromPackages;
  strikethroughEnabled?: boolean;
};

const SailingCard = ({ isBestPriceSailing, onSailingClick, sailing, strikethroughEnabled }: Props) => {
  const cabins = useAppSelector(selectCabins) ?? 1;
  const priceType = useAppSelector(selectPriceType);
  const sailors = useAppSelector(selectSailors) ?? 2;

  const currencyCode = sailing.startingPrice.currencyCode ?? 'USD';
  const sailingPrice =
    priceType === FiltersPriceType.sailorPerNight
      ? Math.ceil(getAmountPerVoyage(sailing.startingPrice, sailing.duration, { sailors }))
      : getAmountPerItem(sailing.startingPrice, { cabins, priceType, sailors });

  const isBlackFridayEnabled = useBlackFridayIsEnabled();

  const leadCabin = sailing?.cabinCategoryCode;
  const isSuite = [CABIN_CATEGORY_CODES.MEGA_ROCKSTAR, CABIN_CATEGORY_CODES.ROCKSTAR_SUITES].includes(leadCabin);

  const classes = cn('SailingCard', {
    blackFridaySailingCard: isBlackFridayEnabled,
    leadCabinType: isSuite,
  });

  const urlPath = usePrecheckoutUrlWithParams(sailing);
  const suiteDescribeId = useId();

  return (
    <div className="SailorCardDetails">
      <Link
        aria-describedby={isSuite ? suiteDescribeId : undefined}
        className={classes}
        data-id={sailing?.id}
        href={urlPath}
        onClick={() => onSailingClick?.(sailing)}
        prefetch={false}
        role="button"
        tabIndex={0}
      >
        <div className="iconYear">
          <SailorCardCalendar /> {formatDate(sailing?.startDate, 'yyyy')}
          {isBestPriceSailing && (
            <span className="bestRateText">
              <UIResource id="Itinerary.BestRate.label" />
            </span>
          )}
        </div>
        <div className="startEndDate">
          <AlmostSoldOut
            className="almostSoldOut"
            classificationCodes={sailing.classificationCodes}
            messageId="AlmostSoldOut.SailingLevel.Emoji"
            withoutStyles
          />
          <span className="startEndDate-content">
            <span className="startDate">{formatDate(sailing?.startDate, 'E, MMM dd')}</span>
            <span className="endDate">
              {' - '}
              {formatDate(sailing?.endDate, 'E, MMM dd')}
            </span>
          </span>
        </div>
        <span className="priceFromLabel">
          <UIResource id="Cabins.Lead.Price.label.from" />{' '}
        </span>
        <span
          className={cn('price', {
            strikethroughEnabled,
          })}
        >
          <Price amount={sailingPrice} currencyCode={currencyCode} />
        </span>

        <AlmostSoldOut
          className="sr-only"
          classificationCodes={sailing.classificationCodes}
          messageId="AlmostSoldOut.lastRemainingCabins"
          withoutStyles
        />
      </Link>
      {isSuite && (
        <div className="leadSuite" id={suiteDescribeId}>
          <UIResource id="VoyageCard.onlySuitesRemaining" />
        </div>
      )}
    </div>
  );
};

export default SailingCard;
