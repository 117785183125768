import { useCallback, useEffect, useRef } from 'react';

type WheelListener = (event: WheelEvent) => void;

const useScrollUnlockRadix = () => {
  const wheelListenerRef = useRef<WheelListener | undefined>();

  const unlockScroll = useCallback(() => {
    document.body?.removeAttribute('data-scroll-locked');
    if (wheelListenerRef.current) {
      document.addEventListener('wheel', wheelListenerRef.current, true);
    }
  }, []);

  const removeUnlockScroll = useCallback(() => {
    if (wheelListenerRef.current) {
      document.removeEventListener('wheel', wheelListenerRef.current, true);
    }
  }, []);

  useEffect(() => {
    wheelListenerRef.current = (event) => {
      event.stopImmediatePropagation();
    };

    return removeUnlockScroll;
  }, [removeUnlockScroll]);

  return { removeUnlockScroll, unlockScroll };
};

export default useScrollUnlockRadix;
