import React from 'react';

import TooltipIcon from '@publicImages/svg/uplift-tooltip-icon.svg';

import Svg from '@/components/Svg';

const UpliftTooltipIcon = () => (
  <span className="Icon UpliftTooltipIcon">
    <Svg src={TooltipIcon} />
  </span>
);

export default UpliftTooltipIcon;
