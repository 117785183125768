import Grabbable from '@/components/Grabbable';

import AccessibleFilterChip from './SelectedFilterChip/AccessibleFilterChip';
import CabinTypeFilterChip from './SelectedFilterChip/CabinTypeFilterChip';
import DurationFilterChip from './SelectedFilterChip/DurationFilterChip';
import PortFilterChip from './SelectedFilterChip/PortFilterChip';
import PriceFilterChip from './SelectedFilterChip/PriceFilterChip';
import ShipFilterChip from './SelectedFilterChip/ShipFilterChip';
import TravelPartyFilterChip from './SelectedFilterChip/TravelPartyFilterChip';
import WeekendFilterChip from './SelectedFilterChip/WeekendFilterChip';

const SelectedFiltersLabelView = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  return (
    <Grabbable className="AppliedFilters" node="section">
      <div className="row">
        <TravelPartyFilterChip onOpenFilterModal={onOpenFilterModal} />
        <AccessibleFilterChip onOpenFilterModal={onOpenFilterModal} />
        <DurationFilterChip onOpenFilterModal={onOpenFilterModal} />
        <WeekendFilterChip onOpenFilterModal={onOpenFilterModal} />
        <CabinTypeFilterChip onOpenFilterModal={onOpenFilterModal} />
        <PriceFilterChip onOpenFilterModal={onOpenFilterModal} />
        <ShipFilterChip onOpenFilterModal={onOpenFilterModal} />
        <PortFilterChip onOpenFilterModal={onOpenFilterModal} />
      </div>
    </Grabbable>
  );
};

export default SelectedFiltersLabelView;
