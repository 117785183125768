import React, { type RefObject, useCallback, useEffect, useId, useRef, useState } from 'react';

import cn from 'classnames';

type ListBoxProps = React.HTMLProps<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
  id?: string;
  onPressEnterItem?: (value: string) => void;
  onSelectItem?: (value: string) => void;
  value?: string;
};

type useListBoxNavProps = {
  onPressEnterItem: ListBoxProps['onPressEnterItem'];
  onSelectItem: ListBoxProps['onSelectItem'];
  ref: RefObject<HTMLDivElement>;
  setFocusedValue: (value?: string) => void;
  value: ListBoxProps['value'];
};

const getCurrentOption = (listBox: HTMLElement | null) => {
  const allOptions = Array.from(listBox?.querySelectorAll('.ListBoxItem') || []) as HTMLElement[];
  const activeDescendantId = listBox?.getAttribute('aria-activedescendant');
  return (activeDescendantId && document.getElementById(activeDescendantId)) || allOptions[0];
};

const updateScroll = (listBox: HTMLElement, selectedOption?: HTMLElement) => {
  if (selectedOption) {
    const scrollBottom = listBox.clientHeight + listBox.scrollTop;
    const elementBottom = selectedOption.offsetTop + selectedOption.offsetHeight;
    if (elementBottom > scrollBottom) {
      listBox.scrollTop = elementBottom - listBox.clientHeight;
    } else if (selectedOption.offsetTop < listBox.scrollTop) {
      listBox.scrollTop = selectedOption.offsetTop;
    }
    selectedOption.scrollIntoView({ block: 'nearest', inline: 'nearest' });
  }
};

const useListBoxKeyboardNav = ({ onPressEnterItem, onSelectItem, ref, setFocusedValue }: useListBoxNavProps) => {
  const onKeyDown = useCallback(
    (e: KeyboardEvent) => {
      if (!ref.current && e.key !== 'ArrowUp' && e.key !== 'ArrowDown' && e.key !== 'Enter' && e.key !== ' ') {
        return;
      }

      e.preventDefault();

      const allOptions = Array.from(ref.current?.querySelectorAll('.ListBoxItem') || []) as HTMLElement[];
      const currentOption = getCurrentOption(ref.current);

      if (!currentOption) {
        return;
      }

      const currentOptionIndex = allOptions.indexOf(currentOption);
      let nextOption: HTMLElement | undefined;

      if (e.key === 'ArrowUp' && currentOptionIndex > -1 && currentOptionIndex > 0) {
        nextOption = allOptions[currentOptionIndex - 1];
      } else if (e.key === 'ArrowDown' && currentOptionIndex > -1 && currentOptionIndex < allOptions.length - 1) {
        nextOption = allOptions[currentOptionIndex + 1];
      } else if (e.key === 'Enter' || e.key === ' ') {
        if (currentOption.dataset.value && onPressEnterItem) {
          onPressEnterItem(currentOption.dataset.value);
        }
        return;
      }

      if (nextOption) {
        setFocusedValue?.(nextOption.dataset.value);
        ref.current?.setAttribute('aria-activedescendant', nextOption.id);
        if (nextOption.dataset.value) {
          onSelectItem?.(nextOption.dataset.value);
          updateScroll(ref.current!, nextOption);
        }
      }
    },
    [ref, onSelectItem, onPressEnterItem, setFocusedValue],
  );

  const onFocus = useCallback(() => {
    const currentOption = getCurrentOption(ref.current);
    if (currentOption) {
      ref.current?.setAttribute('aria-activedescendant', currentOption.id);
      setFocusedValue(currentOption?.dataset.value);
    }
  }, [ref, setFocusedValue]);

  const onBlur = useCallback(() => {
    setFocusedValue?.(undefined);
  }, [setFocusedValue]);

  useEffect(() => {
    const node = ref.current;

    if (node) {
      node.addEventListener('keydown', onKeyDown);
      node.addEventListener('focus', onFocus);
      node.addEventListener('blur', onBlur);
    }

    return () => {
      if (node) {
        node.removeEventListener('keydown', onKeyDown);
        node.removeEventListener('focus', onFocus);
        node.removeEventListener('blur', onBlur);
      }
    };
  }, [ref, onKeyDown, onBlur, onFocus]);
};

export const ListBox = ({ children, className, id, onPressEnterItem, onSelectItem, value, ...rest }: ListBoxProps) => {
  const ref = useRef(null);
  const randomId = `listBox${useId()}`;

  const [selectedValue, setSelectedValue] = useState<string | undefined>(undefined);
  const [focusedValue, setFocusedValue] = useState<string | undefined>(undefined);
  const currentValue = onSelectItem ? value : selectedValue;

  useListBoxKeyboardNav({
    onPressEnterItem,
    onSelectItem: onSelectItem || setSelectedValue,
    ref,
    setFocusedValue,
    value: currentValue,
  });

  const handler = useCallback(
    (child: React.ReactNode): React.ReactNode => {
      const item = child as React.ReactElement;
      if (React.isValidElement(child)) {
        if (item.type === ListItem) {
          return React.cloneElement(child, {
            focused: child.props.value && child.props.value === focusedValue,
            onSelectItem: onSelectItem || setSelectedValue,
            selected: child.props.value && child.props.value === currentValue,
            ...child.props,
          });
        }

        if (item.type === React.Fragment) {
          return React.cloneElement(child, {
            ...child.props,
            children: React.Children.map(child.props.children, handler),
          });
        }
      }
      return child;
    },
    [currentValue, focusedValue, onSelectItem],
  );

  return (
    <div {...rest} className={cn('ListBox', className)} id={id || randomId} ref={ref} role="listbox" tabIndex={0}>
      {React.Children.map(children, handler)}
    </div>
  );
};

type ListItemProps = React.HTMLProps<HTMLDivElement> & {
  children: React.ReactNode;
  className?: string;
  focused?: boolean;
  id?: string;
  onKeyDown?: (e: React.UIEvent) => void;
  onSelectItem?: ListBoxProps['onSelectItem'];
  selected?: boolean;
  value?: string;
};

export const ListItem = ({
  children,
  className,
  focused,
  id,
  onKeyDown,
  onSelectItem,
  selected,
  value,
  ...rest
}: ListItemProps) => {
  const randomId = `listItem${useId()}`;

  return (
    <div
      id={id || randomId}
      {...rest}
      aria-selected={selected === true}
      className={cn('ListBoxItem', className, {
        _focused: focused === true,
        _selected: selected === true,
      })}
      data-value={value}
      onClick={value ? () => onSelectItem?.(value) : undefined}
      role="option"
      tabIndex={-1}
    >
      {React.Children.map(children, (child) => {
        if (React.isValidElement(child)) {
          return React.cloneElement(child, {
            focused,
            ...child.props,
          });
        }
        return child;
      })}
    </div>
  );
};
