import { forwardRef } from 'react';
import type { ComponentType, PropsWithChildren } from 'react';

import cn from 'classnames';

import type { Package } from '@/infra/types/voyageInfo/package';

import useIsPromoActive, {
  MerchandisingBannerComponents,
} from '@/components/PromoBanner/Merchandising/hooks/useIsPromoActive';
import { useUIResourcePool } from '@/ducks/common/resources';
import { useGetBarTabAmount } from '@/widgets/Promo/lib/useGetBarTabAmount';

import './PackageCard.scss';

export type TProps = {
  duration: number;
  isBarTab: boolean;
  isSale: boolean;
};

const UI_RESOURCE_POOL = {
  barTabText: 'Promotion.voyageCardBanner.BarTab',
  saleTitle: 'Promotion.voyageCardBanner.Title',
} as const;

const SaleBadge = ({ duration, isBarTab, isSale }: TProps) => {
  const { barTabText, saleTitle } = useUIResourcePool(UI_RESOURCE_POOL);
  const barTab = useGetBarTabAmount(duration);
  if (!isSale && !isBarTab) {
    return null;
  }

  const isBarTabDynamic = barTabText?.includes('{N}');

  // Context on business requirements on MSH-113559
  let displayMessage = null;
  if (barTabText) {
    if (barTab && isBarTab) {
      displayMessage = barTabText.replace('{N}', barTab);
    } else if (!isBarTabDynamic) {
      displayMessage = barTabText;
    }
  }

  return (
    <div className="PackageCard__badge">
      {displayMessage && <div className="barTab">{displayMessage}</div>}
      {isSale && <div className="sale">{saleTitle}</div>}
    </div>
  );
};

export type TSaleBadgeHostProps = PropsWithChildren<{
  className?: string;
  packageData: Package;
}>;

export const withSaleBadge = <P,>(Component: ComponentType<TSaleBadgeHostProps & P>) => {
  const SaleBadgeWrapper = forwardRef<typeof Component, TSaleBadgeHostProps & P>(
    ({ children, className, packageData, ...props }, ref) => {
      const isSale = useIsPromoActive(packageData.sailingList, MerchandisingBannerComponents.voyageCardMerchandising);
      const isBarTab = useIsPromoActive(packageData.sailingList, MerchandisingBannerComponents.voyageCardBarTab);
      return (
        <Component
          className={cn(isSale && 'sale', packageData?.isPortChangeAvailable && 'portChange', className)}
          packageData={packageData}
          ref={ref}
          {...(props as P)}
        >
          {children}
          <SaleBadge duration={packageData.duration} isBarTab={isBarTab} isSale={isSale} />
        </Component>
      );
    },
  );
  SaleBadgeWrapper.displayName = 'SaleBadgeWrapper';
  return SaleBadgeWrapper as React.ForwardRefExoticComponent<P>;
};
