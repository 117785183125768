import { createContext } from 'react';

import isEqual from 'lodash/isEqual';

import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';
import { type TOptional } from '@/types/common';

import type { TRouteChange, TRouteChangeListener } from './types';

export const CLEANUP_DELAY = 350;

type TItem = {
  listener: TRouteChangeListener;
  unmountedAt?: number;
};

export class RouteChangeContextHandler {
  protected _listeners: TItem[] = [];

  listen = (listener: TRouteChangeListener): (() => void) => {
    const finder = (item: TItem) => item.listener === listener;
    if (!this._listeners.some(finder)) this._listeners.push({ listener });
    return () => {
      const item = this._listeners.find(finder);
      if (item) {
        item.unmountedAt = Date.now();
        setTimeout(this._cleanup, CLEANUP_DELAY);
      }
    };
  };

  fire = (
    nextPath: string,
    nextSearchParams: URLSearchParams,
    prevPath: TOptional<string>,
    prevSearchParams: TOptional<URLSearchParams>,
  ): void => {
    if (this._listeners.length) {
      const nextParams = searchParamsAsObject(nextSearchParams);
      const prevParams = prevSearchParams ? searchParamsAsObject(prevSearchParams) : undefined;
      const change: TRouteChange = {
        areParamsChanged: !isEqual(nextParams, prevParams),
        isPathChanged: nextPath !== prevPath,
        nextParams,
        nextPath,
        prevParams,
        prevPath,
      };
      const unmounted: TItem[] = [];
      for (const item of this._listeners) {
        item.listener(change);
        if (item.unmountedAt) unmounted.push(item);
      }
      if (unmounted.length) this._remove(unmounted);
    }
  };

  protected _remove = (items: TItem[]): void => {
    if (items.length && this._listeners.length) {
      for (const item of items) {
        const i = this._listeners.indexOf(item);
        if (i !== -1) this._listeners.splice(i, 1);
      }
    }
  };

  protected _cleanup = (): void => {
    if (this._listeners.length) {
      const maxTime = Date.now() - CLEANUP_DELAY;
      for (let i = this._listeners.length - 1; i >= 0; i -= 1) {
        const { unmountedAt } = this._listeners[i]!;
        if (unmountedAt && unmountedAt <= maxTime) this._listeners.splice(i, 1);
      }
    }
  };
}

export default createContext<RouteChangeContextHandler>(new RouteChangeContextHandler());
