import type { TCurrencyCode } from '@/infra/types/common';
import type { TImage } from '@/infra/types/common/base';

export enum DestinationType {
  ITINERARIES = 'itineraries',
  PORTS_OF_CALL = 'ports-of-call',
}

export type DestinationRegion<T = DestinationCountryItem | DestinationItineraryItem> = {
  allItemsIds: string[];
  allSelected: boolean;
  id: string;
  image?: TImage;
  items: T[];
  itemsCount?: number;
  label: string;
  order: number;
  selected: boolean;
  selectedItemsIds: string[];
  subLabel?: string;
};

export type DestinationItem = {
  duration?: number;
  id: string;
  image?: TImage;
  label: string;
  order?: number;
  selected: boolean;
  startingPrice?: { amount: number; currencyCode: TCurrencyCode };
  subLabel?: string;
};

export type DestinationItineraryItem = DestinationItem;

export type DestinationPortOfCallItem = DestinationItem;

export type DestinationCountryItem = Partial<DestinationItem> & {
  allItemsIds: string[];
  allSelected: boolean;
  items: DestinationPortOfCallItem[];
  label: string;
  selectedItemsIds: string[];
};

export type DestinationsFilterData = {
  [DestinationType.ITINERARIES]: DestinationRegion<DestinationItineraryItem>[];
  [DestinationType.PORTS_OF_CALL]: DestinationRegion<DestinationCountryItem>[];
};
export type AllItemsIds = Partial<Record<DestinationType, Record<string, string[]>>>;
