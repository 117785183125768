import Image from 'next/image';

import cn from 'classnames';

import UIResource from '@/components/UIResource';
import WithFrenchContent from '@/components/WithFrenchContent';
import { FormCheck, Price } from '@/components/elements';
import { selectDefaultCurrencyCode } from '@/ducks/common/selectors';
import { getAmountTaxAware } from '@/helpers/data/mappers/Summary';
import { useAppSelector } from '@/store';

import { type DestinationItem as Item } from './types';

import './DestinationItem.scss';

type DestinationItemProps = {
  checked: boolean;
  className?: string;
  focused?: boolean;
  id: string;
  item: Item;
  onClick: (id: string) => void;
};

const DestinationItem = ({ checked, className, id, item, onClick }: DestinationItemProps) => {
  const defaultCurrencyCode = useAppSelector(selectDefaultCurrencyCode);
  const labelId = `${id}_label`;

  return (
    <div
      aria-labelledby={labelId}
      className={cn('Itinerary', className)}
      id={id}
      onClick={(e: React.UIEvent) => {
        e.preventDefault();
        onClick(item.id);
      }}
    >
      <FormCheck
        aria-label="select an itinerary to filter the results"
        checked={checked}
        id={`${id}_checkbox`}
        tabIndex={-1}
        type="checkbox"
        value={item.id}
      >
        <div style={{ display: 'flex', justifyContent: 'space-between', width: '100%' }} tabIndex={-1}>
          <div className="Itinerary__row isNewFilter" tabIndex={-1}>
            <div className="Itinerary__textSection isNewFilter" id={labelId}>
              <div className="Itinerary__name isNewFilter">
                <WithFrenchContent text={item.label} />
              </div>
              <div className="Itinerary__description isNewFilter">
                {item.duration && item.startingPrice?.amount && (
                  <UIResource
                    id="RegionRefinements.itineraryDescription"
                    values={{
                      numNights: item.duration,
                      price: (
                        <Price
                          amount={getAmountTaxAware(item.startingPrice)}
                          currencyCode={item.startingPrice.currencyCode || defaultCurrencyCode}
                        />
                      ),
                    }}
                  />
                )}
              </div>
              {/* key prop forces recreation of node for safari Voiceover to properly read contents */}
              <div className="sr-only" key={`${checked}`}>
                {', '}
                {checked ? `Checked` : `Not checked`}
              </div>
            </div>
          </div>
          <div className="Itinerary__image isNewFilter">
            {item.image && <Image alt="" fill sizes="100vw, 100vh" src={item.image.src} />}
          </div>
        </div>
      </FormCheck>
    </div>
  );
};

export default DestinationItem;
