import React, { useCallback } from 'react';

import isFunction from 'lodash/isFunction';

import type { FILTER_KEY } from '@/ducks/filters/types';

import CloseWithoutBackground from '@/components/Icon/ChooseVoyage/CloseWithoutBackground';
import { resetFilters } from '@/ducks/filters/setters';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import useOnKeyDown from '@/hooks/useOnKeyDown';

const SelectedFilterChip = ({
  filterKeys,
  label,
  onBeforeRemoveFilter,
  onOpenFilterModal,
}: {
  filterKeys: FILTER_KEY | FILTER_KEY[];
  label: string;
  onBeforeRemoveFilter?: CallableFunction;
  onOpenFilterModal: CallableFunction;
}) => {
  const openFilterSelectionSection = useCallback(
    (filterName: FILTER_KEY | FILTER_KEY[]) => () => {
      onOpenFilterModal(filterName);
    },
    [onOpenFilterModal],
  );

  const removeSelectedFilter = useCallback(
    (filterKeys: FILTER_KEY | FILTER_KEY[]) => () => {
      if (isFunction(onBeforeRemoveFilter)) {
        onBeforeRemoveFilter();
      }

      resetFilters(filterKeys);
      tracking.trackDefaultFilter(filterKeys);
    },
    [onBeforeRemoveFilter],
  );

  const onKeydownRemove = useOnKeyDown(removeSelectedFilter(filterKeys));
  const onKeyDownOpenFilter = useOnKeyDown(openFilterSelectionSection(filterKeys));

  return (
    <div className="filter" key={filterKeys.toString()}>
      <div
        className="text"
        onClick={openFilterSelectionSection(filterKeys)}
        onKeyDown={onKeyDownOpenFilter}
        role="button"
        tabIndex={0}
      >
        {label}
      </div>
      <div
        aria-label="Remove the filter option"
        className="removeChipAction"
        onClick={removeSelectedFilter(filterKeys)}
        onKeyDown={onKeydownRemove}
        role="button"
        tabIndex={0}
      >
        <CloseWithoutBackground />
      </div>
    </div>
  );
};

export default SelectedFilterChip;
