import React, { useMemo } from 'react';

import { selectMetaType } from '@/ducks/filters/selectors';
import { FILTER_KEY } from '@/ducks/filters/types';
import { getFiltersOptions } from '@/ducks/filtersOptions/selectors';
import { type TFiltersOptions } from '@/infra/types/common/filters';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const CabinTypeFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const cabinType = useAppSelector(selectMetaType);
  const cabinTypes = useAppSelector(getFiltersOptions).cabinTypes as TFiltersOptions['cabinTypes'];

  const label = useMemo(() => {
    if (!cabinType) {
      return null;
    }

    const item = cabinTypes.find(({ code }) => code === cabinType);
    return item?.name || null;
  }, [cabinType, cabinTypes]);

  return label ? (
    <SelectedFilterChip filterKeys={FILTER_KEY.metaType} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default CabinTypeFilterChip;
