import { useContext, useEffect, useRef } from 'react';

import type { TRouteChange, TRouteChangeListener } from './types';

import RouteChangeContext from './Context';

const useOnRouteChange = (listener: TRouteChangeListener): void => {
  const { listen } = useContext(RouteChangeContext);
  const ref = useRef<TRouteChangeListener>();

  useEffect(() => {
    ref.current = listener;
  }, [listener]);

  useEffect(() => listen((change: TRouteChange) => ref.current?.(change)), []);
};

export default useOnRouteChange;
