import { useMemo } from 'react';

import { isEmpty } from 'lodash';

import type { TPromoBannerMerchandisingProps } from '@/features/promoBanners/types';

import { selectMerchandisingBannerDetails } from '@/features/promoBanners/selectors';
import { useAppSelector } from '@/store';

const MerchandisingBannerComponents = {
  barTabHeroImage: 'barTabHeroImageToggle',
  bookingHeader: 'bookingHeaderBackgroundToggle',
  countdownClock: 'countdownClockToggle',
  exitInterstitial: 'exitInterstitial',
  promoBox: 'preCheckoutModuleToggle',
  quickSearch: 'quickSearch',
  rotunda: 'rotundaToggle',
  sailingItem: 'sailingItemToggle',
  voyageCardBarTab: 'voyageCardBarTab',
  voyageCardMerchandising: 'voyageCardMerchandising',
} as const;

export type MerchandisingComponents = keyof typeof MerchandisingBannerComponents;
const SailingIndependent = new Set([
  'bookingHeader',
  'countdownClock',
  'exitInterstitial',
  'quickSearch',
  'rotunda',
] as const);
type SailingIndependentMerchandisingComponents = typeof SailingIndependent extends Set<infer T> ? T : never;

const isComponentEnabled = (
  component: keyof typeof MerchandisingBannerComponents,
  promoInfo: TPromoBannerMerchandisingProps,
) => {
  const key = MerchandisingBannerComponents[component];
  const toggle = promoInfo[key];
  return toggle ?? false;
};

type Sailing = {
  endDate: string;
  id: string;
  startDate: string;
};
type SailingData = Sailing | Sailing[];

export function useIsPromoActive(
  component: SailingIndependentMerchandisingComponents,
  unused_sailingData?: SailingData,
): boolean;
export function useIsPromoActive(
  component: Exclude<MerchandisingComponents, SailingIndependentMerchandisingComponents>,
  sailingData: SailingData,
): boolean;
export function useIsPromoActive(component: MerchandisingComponents, sailingData?: SailingData): boolean {
  const promoInfo = useAppSelector(selectMerchandisingBannerDetails);

  return useMemo(() => {
    if (!promoInfo) return false;

    if (SailingIndependent.has(component)) {
      return isComponentEnabled(component, promoInfo);
    }

    if (!isComponentEnabled(component, promoInfo)) return false;

    if (isEmpty(sailingData)) return true;

    const { sailingsToBeExcluded } = promoInfo;

    const sailings = Array.isArray(sailingData) ? sailingData : [sailingData];
    const involvedSailings = sailingsToBeExcluded?.length
      ? sailings.filter((sailing) => !sailingsToBeExcluded?.includes(sailing?.id))
      : sailings;

    if (involvedSailings.length && (promoInfo?.voyageStart || promoInfo?.voyageEnd)) {
      const start = promoInfo?.voyageStart ? new Date(promoInfo?.voyageStart) : undefined;
      const end = promoInfo?.voyageEnd ? new Date(promoInfo?.voyageEnd) : undefined;
      return involvedSailings?.some(
        ({ endDate, startDate }) =>
          (!start || new Date(startDate) >= new Date(start)) && (!end || new Date(endDate) <= new Date(end)),
      );
    }
    return !!involvedSailings.length;
  }, [component, promoInfo, sailingData]);
}
