import React from 'react';

import CalenderImg from '@publicImages/svg/calendar.svg';

import Svg from '@/components/Svg';

const Calendar = () => (
  <span className="Icon Calendar">
    <Svg src={CalenderImg} />
  </span>
);

export default Calendar;
