import PropTypes from 'prop-types';
import React from 'react';

import classnames from 'classnames';

import { format } from '@/helpers/util/dateUtil';
import useOnKeyDown from '@/hooks/useOnKeyDown';

import { isDefined } from './utils';

const propTypes = {
  applyNewFilterUI: PropTypes.bool,
  hoverIdx: PropTypes.number,
  monthData: PropTypes.shape({
    date: PropTypes.shape({}),
    disabled: PropTypes.bool,
    hideable: PropTypes.bool,
    idx: PropTypes.number.isRequired,
  }).isRequired,
  onMonthClick: PropTypes.func.isRequired,
  onMonthHover: PropTypes.func.isRequired,
  rangeEnd: PropTypes.number,
  rangeStart: PropTypes.number,
};

const defaultProps = {
  applyNewFilterUI: false,
  hoverIdx: null,
  rangeEnd: null,
  rangeStart: null,
};

/**
 * Check if this month is within the start and end range
 * @param {number} idx
 * @param {number} rangeStart
 * @param {number} rangeEnd
 * @returns {boolean}
 */
const isInRange = (idx, rangeStart, rangeEnd) =>
  isDefined(rangeStart) && isDefined(rangeEnd) && idx > rangeStart && idx < rangeEnd;

/**
 * Check if this month is the same as the one being hovered/focussed
 * @param {number} idx
 * @param {number} hoverIdx
 */
const isHover = (idx, hoverIdx) => hoverIdx === idx;

/**
 * Check if this is the hovered month, but also if its the left side of the range
 * @param {number} idx
 * @param {number} rangeStart
 * @param {number} rangeEnd
 * @param {number} hoverIdx
 */
const isStartHover = (idx, rangeStart, rangeEnd, hoverIdx) =>
  (hoverIdx === idx && !isDefined(rangeStart)) ||
  (hoverIdx === idx && idx < rangeStart) ||
  (hoverIdx === idx && hoverIdx > rangeEnd && isDefined(rangeEnd));

/**
 * Check if this is the hovered month, but also if its the right side of the range
 * @param {number} idx
 * @param {number} rangeStart
 * @param {number} rangeEnd
 * @param {number} hoverIdx
 */
const isEndHover = (idx, rangeStart, rangeEnd, hoverIdx) =>
  hoverIdx === idx && hoverIdx > rangeStart && isDefined(rangeStart) && !isDefined(rangeEnd);

/**
 * Is this month in between the start range and the current hover position
 * This is the highlighted state of months when we are mid-selection
 * @param {number} idx
 * @param {number} rangeStart
 * @param {number} rangeEnd
 * @param {number} hoverIdx
 */
const isActiveHover = (idx, rangeStart, rangeEnd, hoverIdx) => {
  if (isDefined(rangeStart) && isDefined(rangeEnd)) {
    return false;
  }

  if (isDefined(rangeStart)) {
    return hoverIdx > rangeStart && idx > rangeStart && idx < hoverIdx;
  }

  return false;
};

const MonthButton = ({ applyNewFilterUI, hoverIdx, monthData, onMonthClick, onMonthHover, rangeEnd, rangeStart }) => {
  const { date, disabled, hideable, idx } = monthData;
  const isInRangeFlag = isInRange(idx, rangeStart, rangeEnd);
  const isRangeEnd = idx === rangeEnd;
  const isRangeStart = idx === rangeStart;
  const isSelected = isInRangeFlag || isRangeStart || isRangeEnd;

  const cssClasses = classnames({
    '-activeHover': isActiveHover(idx, rangeStart, rangeEnd, hoverIdx),
    '-disabled': disabled,
    '-endHover': isEndHover(idx, rangeStart, rangeEnd, hoverIdx),
    '-hideable': hideable,
    '-hoverDate': isHover(idx, hoverIdx),
    '-inRange': isInRangeFlag,
    '-newFilter': applyNewFilterUI,
    '-rangeEnd': isRangeEnd,
    '-rangeStart': isRangeStart,
    '-selectedMonth': !rangeEnd && rangeStart === idx && hoverIdx <= idx,
    '-startHover': isStartHover(idx, rangeStart, rangeEnd, hoverIdx),
    DatePicker__month: true,
  });

  const getAriaLabel = () => {
    let result = format(date, 'MMM yyyy');
    if (isSelected) result += ' selected';
    return result;
  };

  const onClick = () => {
    if (disabled) {
      return;
    }
    onMonthClick(idx);
  };

  const focusMonth = () => {
    if (disabled) {
      return;
    }
    onMonthHover(idx);
  };

  const defocusMonth = () => {
    if (disabled) {
      return;
    }
    onMonthHover(null);
  };

  const onKeyDown = useOnKeyDown((e) => {
    e.preventDefault();
    onClick(e);
  });

  return (
    <button
      aria-label={getAriaLabel(isSelected)}
      className={cssClasses}
      disabled={disabled}
      onBlur={defocusMonth}
      onClick={onClick}
      onFocus={focusMonth}
      onKeyDown={onKeyDown}
      onMouseOut={defocusMonth}
      onMouseOver={focusMonth}
      type="button"
    >
      <span>{format(date, 'MMM')}</span>
    </button>
  );
};

MonthButton.propTypes = propTypes;
MonthButton.defaultProps = defaultProps;

export default MonthButton;
