import PropTypes from 'prop-types';
import React from 'react';

import Lottie from 'react-lottie';

import { Skeleton } from '@/components/Skeleton';
import { env } from '@/environment';

const propTypes = {
  defaultSailor: PropTypes.number,
  selectedSailor: PropTypes.number,
};

const defaultProps = {
  defaultSailor: 2,
  selectedSailor: '',
};

const animations = [
  { present: 2, previous: 1 },
  { present: 3, previous: 1 },
  { present: 4, previous: 1 },
  { present: 1, previous: 2 },
  { present: 3, previous: 2 },
  { present: 4, previous: 2 },
  { present: 1, previous: 3 },
  { present: 2, previous: 3 },
  { present: 4, previous: 3 },
  { present: 1, previous: 4 },
  { present: 2, previous: 4 },
  { present: 3, previous: 4 },
];

class SailorAnimation extends React.PureComponent {
  // TODO: Move repeated code that's also in LuggageAnimation.js to common file
  loadAnimations = () => {
    const promises = [];
    animations.forEach((animation) => {
      const url = `${env.CONTEXT}/static/images/animations/Sailor/${animation.previous}-${animation.present}.json`;
      if (!animation.image) {
        const promise = fetch(url)
          .then((res) => {
            if (!res.ok) {
              throw new Error(res.status);
            }
            return res.json();
          })
          .then((result) => {
            animation.image = result; // eslint-disable-line no-param-reassign
          })
          .catch((e) => console.error('loading animation error: ', e));
        promises.push(promise);
      }
    });
    return Promise.all(promises).then(() => {
      this.setState({ animationsLoaded: true });
    });
  };

  constructor(props) {
    super(props);
    this.state = {
      animationsLoaded: false,
      isPaused: false,
    };
    this.previousSailor = 0;

    this.loadAnimations = this.loadAnimations.bind(this);
  }

  componentDidMount() {
    this.loadAnimations();
  }

  shouldComponentUpdate(nextProps) {
    if (this.props.selectedSailor !== nextProps.selectedSailor) {
      this.previousSailor = this.props.selectedSailor;
    }

    return true;
  }

  componentDidUpdate() {
    // Crutch to make library static DOM element unreadable for screen readers
    const lottieEl = document.getElementById('lottie-sailors')?.firstChild;
    lottieEl?.removeAttribute('role');
    lottieEl?.removeAttribute('aria-label');
    lottieEl?.removeAttribute('tabindex');
  }

  getAnimation = () => {
    const { previousSailor } = this;
    const { defaultSailor, selectedSailor } = this.props;

    return animations.find((item) => {
      const isInitState = previousSailor === 0;
      const previousCount = isInitState ? defaultSailor : previousSailor;
      const presentCount = selectedSailor;
      const isPrevAsPresent = previousCount === presentCount;

      return item.previous === (isPrevAsPresent ? 1 : previousCount) && item.present === presentCount;
    })?.image;
  };

  render() {
    const { animationsLoaded, isPaused } = this.state;
    if (!animationsLoaded) {
      return <Skeleton height="78px" inDarkBg width="287px" />;
    }

    const animationData = this.getAnimation();

    const lottieOptions = {
      animationData,
      autoplay: true,
      loop: false,
    };

    return (
      <div id="lottie-sailors">
        <Lottie height={78} isClickToPauseDisabled isPaused={isPaused} options={lottieOptions} width={287} />
      </div>
    );
  }
}

SailorAnimation.propTypes = propTypes;
SailorAnimation.defaultProps = defaultProps;

export default SailorAnimation;
