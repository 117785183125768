import { useMemo, useRef } from 'react';

import useGetRefinementPopoverProps from '@/components/ResultRefinements/RefinementPopover/useGetRefinementPopoverProps';

import DestinationRefinement from './index';

type UseRefinementFiltersProps = {
  id?: string;
  isMobile: boolean;
  onChangeStatus: (isOpen: boolean) => void;
};

const useDestinationRefinementPopover = ({ isMobile, onChangeStatus }: UseRefinementFiltersProps) => {
  const buttonRef = useRef<HTMLElement | null>(null);

  const popoverProps = useGetRefinementPopoverProps({
    buttonRef,
    focusTrapDisableEvents: ['x-arrows', 'y-arrows'],
    isMobile,
    onChangeStatus,
    placement: 'bottom-start',
  });

  const popover = useMemo(
    () => <DestinationRefinement isMobile={isMobile} key="DestinationRefinementKey" popoverProps={popoverProps} />,
    [isMobile, popoverProps],
  );

  return {
    ...popoverProps,
    buttonProps: popoverProps.getReferenceProps(/* { className: ... } */),
    popover,
  };
};

export default useDestinationRefinementPopover;
