import { useMemo } from 'react';

import { useUIResourcePool } from '@/ducks/common/resources';
import { FiltersSortType } from '@/infra/types/common/filters';

export const useSortOptions = () => {
  const { date, durationAsc, durationDesc, priceAsc, priceDesc } = useUIResourcePool({
    date: 'VoyageSort.Date',
    durationAsc: 'VoyageSort.Shortest',
    durationDesc: 'VoyageSort.Longest',
    priceAsc: 'VoyageSort.PriceLowest',
    priceDesc: 'VoyageSort.PriceHighest',
  });
  return useMemo(
    () => [
      {
        enabled: true,
        label: date,
        value: FiltersSortType.date,
      },
      {
        enabled: true,
        label: durationDesc,
        value: FiltersSortType.durationDesc,
      },
      {
        enabled: true,
        label: durationAsc,
        value: FiltersSortType.durationAsc,
      },
      {
        enabled: true,
        label: priceDesc,
        value: FiltersSortType.priceDesc,
      },
      {
        enabled: true,
        label: priceAsc,
        value: FiltersSortType.priceAsc,
      },
    ],
    [date, durationAsc, durationDesc, priceAsc, priceDesc],
  );
};
