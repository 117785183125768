import React from 'react';

import ShipImg from '@publicImages/svg/ship.svg';

import Svg from '@/components/Svg';

const Ship = () => (
  <span className="Icon Ship">
    <Svg src={ShipImg} />
  </span>
);

export default Ship;
