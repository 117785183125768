import type { vwoInstance as VwoInstance } from 'vwo-node-sdk';

import { getVWOUserId } from '@/helpers/vwo/getVWOUserId';
import getInstance from '@/helpers/vwo/instance';
import { VWOFlag } from '@/types/vwo';

import type { VWOFeatureVariableKey, VWOFeatureVariableValue } from './types';

export const getVWOFeatureVariableValue = async (
  vwoFeatureName: VWOFlag,
  vwoFeatureVariableKey: VWOFeatureVariableKey,
): Promise<VWOFeatureVariableValue> => {
  let isVwoFeatureEnabled: boolean = false;
  let vwoFeatureVariableValue: VWOFeatureVariableValue = null;
  let vwoInstance: VwoInstance | null = null;

  if (!vwoFeatureName || !vwoFeatureVariableKey) {
    return vwoFeatureVariableValue;
  }

  const userId = getVWOUserId();

  try {
    vwoInstance = await getInstance();
  } catch (err) {
    console.error(`Could not retrieve VWO instance.`);
  }

  try {
    if (vwoInstance) {
      isVwoFeatureEnabled = vwoInstance.isFeatureEnabled(vwoFeatureName, userId);
    }
  } catch (err) {
    console.error(`VWO feature with name '${vwoFeatureName}' can not be read.`);
  }

  try {
    if (isVwoFeatureEnabled && vwoInstance) {
      vwoFeatureVariableValue = vwoInstance.getFeatureVariableValue(vwoFeatureName, vwoFeatureVariableKey, userId);
    }
  } catch (err) {
    console.error(
      `VWO feature with name '${vwoFeatureName}' and variable name '${vwoFeatureVariableKey}' can not be read.`,
    );
  }

  return vwoFeatureVariableValue;
};
