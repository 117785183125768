import { useEffect, useState } from 'react';

import { getAuthFromStorage } from '@/helpers/auth/core';

export const useIsLoggedIn = () => {
  const [isLoggedIn, setIsLoggedIn] = useState<boolean>(false);

  useEffect(() => {
    const isAuth = getAuthFromStorage();
    setIsLoggedIn(!!isAuth);
  }, []);

  return isLoggedIn;
};
