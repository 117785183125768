import PropTypes from 'prop-types';
import { useCallback } from 'react';

import useOnKeyDown from '@/hooks/useOnKeyDown';

import Arrow from '../Icon/Arrow';
import MonthButton from './MonthButton';

const setFocusOnArrow = (event) => {
  const button = event.currentTarget;
  const isLeft = button.matches('._left');
  const parent = button.closest('.DatePicker');
  if (button) {
    setTimeout(() => {
      const buttons = parent.querySelectorAll('.YearGroup__arrow');
      buttons[buttons.length > 1 && !isLeft ? 1 : 0].focus();
    }, 0);
  }
};

const propTypes = {
  applyNewFilterUI: PropTypes.bool,
  hideBothArrows: PropTypes.bool,
  hideLeftArrow: PropTypes.bool,
  hideRightArrow: PropTypes.bool,
  hoverIdx: PropTypes.number,
  isLeftArrowDisabled: PropTypes.bool.isRequired,
  isRightArrowDisabled: PropTypes.bool.isRequired,
  isShowHeader: PropTypes.bool,
  label: PropTypes.string.isRequired,
  months: PropTypes.arrayOf(
    PropTypes.shape({
      date: PropTypes.shape({}).isRequired,
      disabled: PropTypes.bool,
    }).isRequired,
  ).isRequired,
  onLeftArrowClick: PropTypes.func.isRequired,
  onMonthClick: PropTypes.func.isRequired,
  onMonthHover: PropTypes.func.isRequired,
  onRightArrowClick: PropTypes.func.isRequired,
  rangeEnd: PropTypes.number,
  rangeStart: PropTypes.number,
  resetYears: PropTypes.func.isRequired,
  yearIndex: PropTypes.number.isRequired,
};

const defaultProps = {
  applyNewFilterUI: false,
  hideBothArrows: false,
  hideLeftArrow: false,
  hideRightArrow: false,
  hoverIdx: null,
  isShowHeader: true,
  rangeEnd: null,
  rangeStart: null,
};

const YearGroup = ({
  applyNewFilterUI,
  hideBothArrows,
  hideLeftArrow,
  hideRightArrow,
  hoverIdx,
  isLeftArrowDisabled,
  isRightArrowDisabled,
  isShowHeader,
  label,
  months,
  onLeftArrowClick,
  onMonthClick,
  onMonthHover,
  onRightArrowClick,
  rangeEnd,
  rangeStart,
  resetYears,
  yearIndex,
}) => {
  const onClick = useCallback(
    (event) => {
      const button = event.currentTarget;
      if (button) (button.matches('._left') ? onLeftArrowClick : onRightArrowClick)(+button.dataset.clickPayload);
      setFocusOnArrow(event);
    },
    [onLeftArrowClick, onRightArrowClick],
  );

  const onKeyDown = useOnKeyDown(onClick, { isDefaultPrevented: true });

  return (
    <div className="DatePicker__yearGroup">
      {isShowHeader && (
        <>
          <div className="datepickerHeader">Travel dates</div>
          <span className="resetMonthsSelected" onClick={() => resetYears()}>
            Reset
          </span>
        </>
      )}
      <div className="arrowsNYear">
        {!hideLeftArrow && !hideBothArrows && (
          <button
            aria-label="Move to previous year"
            className="YearGroup__arrow _left"
            data-click-payload={yearIndex}
            disabled={isLeftArrowDisabled}
            onClick={onClick}
            onKeyDown={onKeyDown}
          >
            <Arrow />
          </button>
        )}
        <h2>{label}</h2>
        {!hideRightArrow && !hideBothArrows && (
          <button
            aria-label="Move to next year"
            className="YearGroup__arrow _right"
            data-click-payload={yearIndex - 1}
            disabled={isRightArrowDisabled}
            onClick={onClick}
            onKeyDown={onKeyDown}
          >
            <Arrow />
          </button>
        )}
      </div>
      <div className="DatePicker__monthContainer">
        {months.map((m) => (
          <MonthButton
            applyNewFilterUI={applyNewFilterUI}
            hoverIdx={hoverIdx}
            key={m.date.valueOf()}
            monthData={m}
            onMonthClick={onMonthClick}
            onMonthHover={onMonthHover}
            rangeEnd={rangeEnd}
            rangeStart={rangeStart}
          />
        ))}
      </div>
    </div>
  );
};

YearGroup.defaultProps = defaultProps;
YearGroup.propTypes = propTypes;

export default YearGroup;
