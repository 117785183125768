import React from 'react';

import ShipImg from '@publicImages/svg/ship-side-view.svg';

import Svg from '@/components/Svg';

const ShipSideView = () => (
  <span className="Icon ShipNew">
    <Svg src={ShipImg} />
  </span>
);

export default ShipSideView;
