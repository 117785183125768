import React from 'react';

import Trending from '@publicImages/svg/trending.svg?static';

import './PromotionTrending.scss';

const PromotionTrending = () => (
  <div className="trendingBox">
    <Trending />
    <div className="sr-only">Trending</div>
  </div>
);

export default PromotionTrending;
