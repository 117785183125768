import { useIsDesktop, useIsMobile } from '../hooks/useIsDevice';

type withIsMobileProps = {
  isMobile: boolean;
};

export const withIsMobile = <TProps,>(WrappedComponent: React.JSXElementConstructor<TProps & withIsMobileProps>) =>
  function IsMobileWrapper(props: TProps) {
    const isMobile = useIsMobile();
    return <WrappedComponent {...props} isMobile={isMobile} />;
  };

type withIsDesktopProps = {
  isDesktop: boolean;
};

export const withIsDesktop = <TProps,>(WrappedComponent: React.JSXElementConstructor<TProps & withIsDesktopProps>) =>
  function IsDesktopWrapper(props: TProps) {
    const isDesktop = useIsDesktop();
    return <WrappedComponent {...props} isDesktop={isDesktop} />;
  };
