import { useCallback } from 'react';

import UIResource from '@/components/UIResource';
import { resetAKFlowFullyAlongWithFilters, resetAKFlowSearchCriteria } from '@/ducks/accessKeys/utils';
import { useAppDispatch } from '@/store';

import TrueZeroResults from './TrueZeroResults';

const AKTrueZeroResults = () => {
  const dispatch = useAppDispatch();

  const resetSearchCriteria = useCallback(() => {
    dispatch(resetAKFlowSearchCriteria());
  }, [dispatch]);

  const resetFlow = useCallback(() => {
    dispatch(resetAKFlowFullyAlongWithFilters());
  }, [dispatch]);

  return (
    <TrueZeroResults
      description={<UIResource id="ChooseVoyage.ZeroResults.AKheading" />}
      emoji={<UIResource id="ChooseVoyage.ZeroResults.AKemojis" />}
      firstButtonText={<UIResource id="ChooseVoyage.ZeroResults.AKprimaryCTA" />}
      onFirstButtonClick={resetSearchCriteria}
      onSecondButtonClick={resetFlow}
      secondButtonText={<UIResource id="ChooseVoyage.ZeroResults.AKsecondaryCTA" />}
    />
  );
};

export default AKTrueZeroResults;
