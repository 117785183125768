import PackageCardMediaSkeleton from '../PackageCardMedia/PackageCardMediaSkeleton';
import PackageCardTextContentSkeleton from '../PackageCardTextContent/PackageCardTextContentSkeleton';

import './PackageCard.scss';

const PackageCardSkeleton = () => (
  <div className="PackageCard PackageCard__Skeleton">
    <PackageCardMediaSkeleton />
    <PackageCardTextContentSkeleton />
  </div>
);

export default PackageCardSkeleton;
