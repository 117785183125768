import { createSelector } from '@reduxjs/toolkit';
import isEmpty from 'lodash/isEmpty';

import { combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import { selectShipList } from '@/ducks/filters/selectors';

export const selectShipsLabel = createSelector(selectShipList, selectResources, (ships, resources) => {
  if (isEmpty(ships)) {
    return combineUIResource(resources, 'AdvancedFilter.Ship.Default.value');
  }

  const firstShip = ships[0]!.name;

  if (ships.length === 1) {
    return combineUIResource(resources, 'AdvancedFilter.Ship.value', { n: firstShip });
  }

  if (ships.length === 2) {
    return combineUIResource(resources, 'AdvancedFilter.Ship.value.other', { m: 1, n: firstShip });
  }

  return combineUIResource(resources, 'AdvancedFilter.Ship.value.multiple', { m: ships.length - 1, n: firstShip });
});
