import { usePathname, useSearchParams } from 'next/navigation';
import { useContext, useEffect, useRef } from 'react';

import RouteChangeContext from './Context';

const useRouteChangeObserver = () => {
  const path = usePathname()!;
  const params = useSearchParams()!;
  const refPrevPath = useRef<string>();
  const refPrevParams = useRef<URLSearchParams>();
  const { fire } = useContext(RouteChangeContext);

  useEffect(() => {
    const prevPath = refPrevPath.current;
    const prevParams = refPrevParams.current;
    refPrevPath.current = path;
    refPrevParams.current = params;
    fire(path, params, prevPath, prevParams);
  }, [params, path]);
};

export default useRouteChangeObserver;
