export const ATTRS = {
  frameBorder: '0',
  marginHeight: 0,
  marginWidth: 0,
  scrolling: 'no',
};

type IFrameProps = {
  className: string;
  content: string;
} & React.ComponentPropsWithoutRef<'iframe'>;

const IFrame = ({ className, content, ...restAttrs }: IFrameProps) => {
  return (
    <iframe
      className={className}
      ref={(element) => {
        if (content && element && element.contentWindow) {
          const doc = element.contentWindow.document;
          doc.open();
          doc.write(content);
          doc.close();
        }
      }}
      {...ATTRS}
      {...restAttrs}
      aria-label={className}
      title={className}
    />
  );
};

export default IFrame;
