import { createSelector } from '@reduxjs/toolkit';

import { completeMonthLabel } from '@/helpers/util/dateUtil';

import { selectFromDate, selectToDate } from '../selectors';

export const selectTravelDatesLabel = createSelector(
  selectFromDate,
  selectToDate,
  (appliedStartDate, appliedEndDate) => {
    const startMonth = completeMonthLabel(appliedStartDate);
    const endMonth = completeMonthLabel(appliedEndDate);

    if (!startMonth || !endMonth) {
      return '';
    }

    return [startMonth, endMonth].join(' - ');
  },
);
