import React, { type MouseEventHandler } from 'react';

import Icon from '@publicImages/svg/playback-pause.svg?icon';

type PauseProps = {
  onClick: MouseEventHandler;
};

const Pause = (props: PauseProps) => <Icon {...props} />;

export default Pause;
