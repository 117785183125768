import { useSearchParams } from 'next/navigation';

import { useRouter } from 'next-nprogress-bar';

type withRouterProps = {
  router: ReturnType<typeof useRouter>;
};

const withRouter = <TProps,>(WrappedComponent: React.JSXElementConstructor<TProps & withRouterProps>) =>
  function RouterWrapper(props: TProps) {
    const router = useRouter();
    const searchParams = useSearchParams();
    return <WrappedComponent {...props} router={router} searchParams={searchParams} />;
  };

export default withRouter;
