import { Skeleton } from '@/components/Skeleton';

import './PackagePrice.scss';

const PackagePriceSkeleton = () => (
  <div className="PackagePrice">
    <div className="fromLabel">
      <Skeleton inline width="40%" />
    </div>
    <div className="priceTotal">
      <Skeleton inline width="60%" />
    </div>
    <div className="priceFootNote">
      <Skeleton inline width="60%" />
    </div>
    <div className="priceFootNote">
      <Skeleton height="18px" inline width="40%" />
    </div>
  </div>
);

export default PackagePriceSkeleton;
