import type { AppDispatch, AppGetState } from '@/store';

import { clearMultiCurrencies } from '@/ducks/filtersOptions/helpers';
import { FM_AGENCY_ID_KEY, FM_AGENT_ID_KEY, FM_BOOKING_CHANNEL_KEY } from '@/ducks/fm/constants';
import { selectAgentBookingChannel } from '@/ducks/fm/selectors';
import { removeAgencyData } from '@/ducks/fm/setters';
import { agentDataClear } from '@/ducks/fm/slice';
import { routes } from '@/ducks/routes';

export const resetFmFlow = (additionalParams?: string[]) => async (dispatch: AppDispatch, getState: AppGetState) => {
  const state = getState();
  const canRemoveBookingChannel =
    selectAgentBookingChannel(state) === new URLSearchParams(window.location.search).get(FM_BOOKING_CHANNEL_KEY);

  removeAgencyData();
  dispatch(clearMultiCurrencies());
  dispatch(agentDataClear());

  await routes.searchWithout([
    ...(additionalParams || []),
    FM_AGENCY_ID_KEY,
    FM_AGENT_ID_KEY,
    ...(canRemoveBookingChannel ? [FM_BOOKING_CHANNEL_KEY] : []),
  ]);
};
