import { createSelector } from '@reduxjs/toolkit';

import { combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import { selectCabins, selectSailors } from '@/ducks/filters/selectors';

export const selectSailorsLabel = createSelector(
  selectSailors,
  selectCabins,
  selectResources,
  (sailors, cabins, resources) => {
    const sailorsLabelId = sailors > 1 ? 'TravelPartyRefinement.sailors' : 'TravelPartyRefinement.sailor';
    const cabinsLabelId = cabins > 1 ? 'TravelPartyRefinement.cabins' : 'TravelPartyRefinement.cabin';

    return (
      `${sailors} ${combineUIResource(resources, sailorsLabelId)} ,` +
      ` ${cabins} ${combineUIResource(resources, cabinsLabelId)}`
    );
  },
);
