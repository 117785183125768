import { createSelector } from '@reduxjs/toolkit';

import { selectDestinationsFilterData } from '@/components/ResultRefinements/DestinationRefinement/selectors';
import { DestinationType } from '@/components/ResultRefinements/DestinationRefinement/types';
import { getSelectedRegions } from '@/components/ResultRefinements/DestinationRefinement/utils';
import { combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';

export const selectDestinationsLabel = createSelector(
  selectDestinationsFilterData,
  selectResources,
  (data, resources) => {
    const selectedRegions = getSelectedRegions(data);

    const allRegions =
      selectedRegions.length === 0 || selectedRegions.length === data[DestinationType.ITINERARIES].length;

    if (allRegions) {
      return combineUIResource(resources, 'ChooseVoyage.Results.AllDestinations');
    }

    return selectedRegions
      .reduce<string[]>((labels, region) => (region.label ? [...labels, region.label] : labels), [])
      .join(' & ');
  },
);

export const selectDestinationsPillLabel = createSelector(
  selectDestinationsFilterData,
  selectResources,
  (data, resources) => {
    const selectedRegions = getSelectedRegions(data);

    if (!selectedRegions.length) {
      return combineUIResource(resources, 'Destination.filter.text');
    }

    return selectedRegions
      .slice(0, 2)
      .map(({ allSelected, label, selectedItemsIds }) =>
        allSelected ? label : `${label} (${selectedItemsIds.length})`,
      )
      .join(', ');
  },
);
