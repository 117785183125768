import React from 'react';

import tagmanager from '@/tagmanager';

type Props = {
  isOpenFilterSection: boolean;
  onRenderChange: (...args: any[]) => void;
  toggleFilterState: (state: boolean) => void;
};

class BaseFilter extends React.Component<Props> {
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  onRenderChange = (state: boolean) => {};

  toggleFilterSectionState = (filterName: string[]) => {
    const { isOpenFilterSection, onRenderChange: onRenderChangeProps, toggleFilterState } = this.props;

    const newState = !isOpenFilterSection;
    onRenderChangeProps?.(false);
    this?.onRenderChange?.(false);
    toggleFilterState(newState);

    if (newState) {
      tagmanager.tracker.voyagesFilter.trackOpenSubFilter({ label: filterName });
    }
  };
}

export default BaseFilter;
