import React, { type MouseEventHandler } from 'react';

import Icon from '@publicImages/svg/playback-play-small.svg?icon';

type PlayProps = {
  onClick: MouseEventHandler;
};

const PlaySmall = (props: PlayProps) => <Icon {...props} />;

export default PlaySmall;
