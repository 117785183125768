import React from 'react';

import { selectDurationsChipLabel } from '@/ducks/filters/labels/durations';
import { FILTER_KEY } from '@/ducks/filters/types';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const DurationFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const label = useAppSelector(selectDurationsChipLabel);

  return label ? (
    <SelectedFilterChip filterKeys={FILTER_KEY.durations} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default DurationFilterChip;
