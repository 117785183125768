import { Skeleton } from '@/components/Skeleton';

import PackagePriceSkeleton from '../PackagePrice/PackagePriceSkeleton';
import SailingCardSkeleton from '../SailingCard/SailingCardSkeleton';

import './PackageCardTextContent.scss';

const PackageCardTextContentSkeleton = () => (
  <div className="PackageCardTextContent__textContent">
    <PackagePriceSkeleton />
    <div className="sailingCards">
      <SailingCardSkeleton />
      <SailingCardSkeleton />
      <SailingCardSkeleton />
    </div>
    <Skeleton width="50%" />
  </div>
);

export default PackageCardTextContentSkeleton;
