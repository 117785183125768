import PropTypes from 'prop-types';
import React from 'react';

import { useGetBFResources } from '@/ducks/programs/blackFriday/hooks';

import BlackFridayPromoBanner from './BlackFridayPromotionBanner';
import PromotionBannerCard from './PromotionBannerCard';

const BANNER_VARIANTS = {
  LEADERBOARD: 'leaderboard',
  STANDARD: 'standard',
};

const BANNER_MAP = {
  [BANNER_VARIANTS.LEADERBOARD]: ({ bannerEyebrow, bannerMainPromoBenefit, bannerValueProp }) => (
    <BlackFridayPromoBanner
      bannerEyebrow={bannerEyebrow}
      bannerMainPromoBenefit={bannerMainPromoBenefit}
      bannerValueProp={bannerValueProp}
    />
  ),
  [BANNER_VARIANTS.STANDARD]: ({ isSinglePackage }) => <PromotionBannerCard isSinglePackage={isSinglePackage} />,
};

const PromotionBannerVariator = (props) => {
  const { bannerEyebrow, bannerMainPromoBenefit, bannerValueProp, promoBannerVariant } = useGetBFResources();
  const BannerComponent = BANNER_MAP[promoBannerVariant];

  if (!BannerComponent) {
    return null;
  }

  return (
    <BannerComponent
      {...props}
      bannerEyebrow={bannerEyebrow}
      bannerMainPromoBenefit={bannerMainPromoBenefit}
      bannerValueProp={bannerValueProp}
    />
  );
};

PromotionBannerVariator.defaultProps = {
  isSinglePackage: false,
};

PromotionBannerVariator.propTypes = {
  isSinglePackage: PropTypes.bool,
};

export default PromotionBannerVariator;
