import {
  addMonths,
  addYears,
  endOfMonth,
  endOfYear,
  getYear,
  isAfter,
  isBefore,
  isSameMonth,
  isSameYear,
  startOfYear,
} from 'date-fns';
import chunk from 'lodash/chunk';
import groupBy from 'lodash/groupBy';

/**
 * Creates an array of dates, each one corresponding to the 1st of the month, for every
 * month between the start and end dates
 * @param {Date|number|string} startDate
 * @param {Date|number|string} endDate
 * @param {number} minYears
 * @returns {array}
 */

export const createMonthList = (startDate, endDate, minYears = 0) => {
  // Round dates to start/end of the year
  let currentDate = startOfYear(startDate);
  let paddedEndDate = endOfYear(endDate);
  if (minYears > 0) {
    const deficit = minYears + getYear(currentDate) - getYear(paddedEndDate) - 1;
    if (deficit > 0) paddedEndDate = addYears(paddedEndDate, deficit);
  }

  const monthList = [];
  let idx = 0;

  while (isBefore(currentDate, paddedEndDate)) {
    monthList.push({
      date: currentDate,
      disabled: isBefore(endOfMonth(currentDate), startDate) || isAfter(currentDate, endDate),
      idx,
    });
    idx += 1;
    currentDate = addMonths(currentDate, 1);
  }
  return monthList;
};

/**
 * Months exist in rows of 4 cols, where *none* of those 4 months are selectable,
 * then *all 4* are deemed hideable.
 * @param {Array} monthList
 */
export const makeMonthsHideable = (monthList) => {
  const monthRows = chunk(monthList, 4);
  monthRows.forEach((monthRow) => {
    const areAllDisabled = monthRow.every((month) => month.disabled);
    // If all 4 are disabled, make them all hideable
    if (areAllDisabled) {
      monthRow.forEach((month) => {
        // eslint-disable-next-line no-param-reassign
        month.hideable = true;
      });
    }
  });
};

/**
 * Accept a long list of month objects, group them into years,
 * and sort those years into chronological order
 * @param {Array} months;
 * @returns {Array} - array of year objects
 */
export const groupIntoYears = (months) => {
  const groupedObj = groupBy(months, (month) => getYear(month.date));
  const sortedYearKeys = Object.keys(groupedObj)
    .map((key) => parseInt(key, 10))
    .sort();

  return sortedYearKeys.map((yearKey) => ({
    months: groupedObj[yearKey],
    year: yearKey,
  }));
};

/**
 * Returns true if a value is truthy, or zero
 * @param {*} val
 */
export const isDefined = (val) => val || val === 0;

/**
 * Find the index of a month object that has the same date as the one provided
 * @param {Date} date
 * @param {Array} monthList
 */
export const getIndexFromMonthList = (date, monthList) => {
  const monthObject = monthList?.find((month) => isSameMonth(month.date, date) && isSameYear(month.date, date));
  return monthObject && monthObject.idx;
};
