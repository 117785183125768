import { useMemo } from 'react';

import type { TCurrencyCode } from '@/infra/types/common';

import Select from '@/components/Select';
import { selectMultiCurrencyOptions } from '@/ducks/common/lookup/selectors';
import { selectDefaultCurrencyCode, selectLookup } from '@/ducks/common/selectors';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { selectIsAgencyDataAvailable } from '@/ducks/fm/selectors';
import { useMNVV } from '@/ducks/mnvv/hooks';
import { useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import classes from './CurrencyDropdown.module.scss';

const {
  tracker: { createTracker },
  trackerConstants,
} = tagmanager;

const trackEvent = createTracker({
  location: trackerConstants.MODULES.HEADER,
});

export const makeCurrencyOption = (currency: { code: string; flagIcon: string; symbol: string }) => ({
  enabled: true,
  label: (
    <div className={classes.option}>
      <span aria-hidden="true">{currency.flagIcon}</span>
      <span>{currency.symbol}</span>
      <span>{currency.code}</span>
    </div>
  ),
  value: currency.code,
});

const CurrencyDropdown = () => {
  const isFmLinking = useAppSelector(selectIsAgencyDataAvailable);
  const lookupCurrencies = useAppSelector((state) => selectLookup(state)?.currencies) ?? [];

  const defaultCurrencyCode = useAppSelector(selectDefaultCurrencyCode);
  const multiCurrencies = useAppSelector(selectMultiCurrencyOptions);
  const currencies = isFmLinking ? multiCurrencies : lookupCurrencies;

  const { isMNVV } = useMNVV();

  const currencyCode = useAppSelector(selectCurrencyCode);

  const currencyOptions = useMemo(() => currencies.map(makeCurrencyOption), [currencies]);

  const onApply = async (value: TCurrencyCode) => {
    setFilter.currencyCode(value);

    trackEvent({
      action: trackerConstants.EVENT_ACTIONS.CURRENCY_CONVERTER_CLICK,
      label: value,
    });
  };

  const isDisabled = isMNVV || currencyOptions?.length <= 1;
  return (
    <Select
      aria-label="Currency"
      className={classes.root}
      defaultValue={defaultCurrencyCode}
      disabled={isDisabled}
      dropdownClassName={classes.portal}
      onValueChange={onApply}
      options={currencyOptions}
      value={currencyCode}
    />
  );
};

export default CurrencyDropdown;
