import PropTypes from 'prop-types';
import React from 'react';

import { FormattedMessage } from '@/helpers/formatted-message';

import Flyout from '../Flyout';
import { Button } from '../elements';

import './DisabledSailorPopup.scss';

const DisabledSailorPopup = ({ onDismissClick, onSailorsericeBtnClick, showModal, tabIndex }) => (
  <Flyout className="DisabledSailorModal" direction="modal" onDismiss={onDismissClick} open={showModal}>
    <div className="DisabledSailorModal__container">
      <h1 className="DisabledSailorModal__heading">
        <FormattedMessage defaultMessage="Please Contact Sailor Services" id="MNVV.OneSailorError.heading" />
      </h1>
      <p className="DisabledSailorModal__subHeading">
        <FormattedMessage
          defaultMessage="You are not allowed to select one sailor in MNVV reservation"
          id="MNVV.OneSailorError.body"
        />
      </p>
      <div className="DisabledSailorModal__footer">
        <div className="SailorServiceCta">
          <Button className="fullwidth" onClick={onSailorsericeBtnClick} tabIndex={tabIndex} variant="secondary">
            <FormattedMessage defaultMessage="Contact Sailor Services" id="MNVV.OneSailorError.PrimaryCTA" />
          </Button>
        </div>
        <div className="CloseBtnCta">
          <Button className="fullwidth" onClick={onDismissClick} tabIndex={tabIndex} variant="secondary">
            <FormattedMessage defaultMessage="Close" id="MNVV.OneSailorError.SecondaryCTA" />
          </Button>
        </div>
      </div>
    </div>
  </Flyout>
);

const propTypes = {
  onDismissClick: PropTypes.func,
  onSailorsericeBtnClick: PropTypes.func,
  showModal: PropTypes.bool,
  tabIndex: PropTypes.string,
};

const defaultProps = {
  onDismissClick: () => {},
  onSailorsericeBtnClick: () => {},
  showModal: false,
  tabIndex: '0',
};

DisabledSailorPopup.propTypes = propTypes;
DisabledSailorPopup.defaultProps = defaultProps;

export default DisabledSailorPopup;
