import React from 'react';

import FilterDestinationImg from '@publicImages/svg/filter-destination.svg';

import Svg from '@/components/Svg';

const FilterDestination = () => (
  <span className="Icon FilterDestination">
    <Svg src={FilterDestinationImg} />
  </span>
);

export default FilterDestination;
