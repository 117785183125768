import { useEffect, useState } from 'react';

import { isServerSide } from '@/helpers/isServerSide';

const isIntersected = (targetAOptions: ScrollTargetOptions, offsetOptions: ScrollTargetOptions) => {
  const { offset: targetAOffset = 0, prop: targetAProp, selector: targetASelector } = targetAOptions;
  const { offset: targetBOffset = 0, prop: targetBProp, selector: targetBSelector } = offsetOptions;
  const targetA = document.querySelector(targetASelector);
  const targetB = document.querySelector(targetBSelector);
  if (targetA == null || targetB == null) {
    return false;
  }

  const targetAValue = targetA.getBoundingClientRect()[targetAProp] ?? 0;
  const targetABorder = targetAValue + targetAOffset;
  const targetBValue = targetB.getBoundingClientRect()[targetBProp] ?? 0;
  const targetBBorder = targetBValue + targetBOffset;

  return targetABorder <= targetBBorder;
};

type ScrollTargetOptions = {
  offset?: number;
  prop: Exclude<keyof DOMRect, 'toJSON'>;
  selector: string;
};

const useScrollIntersection = (
  targetAOptions: ScrollTargetOptions,
  targetBOptions: ScrollTargetOptions,
  scrollTarget?: Element,
) => {
  const [isScrolledState, setIsScrolled] = useState(false);

  useEffect(() => {
    if (!scrollTarget && isServerSide()) {
      return;
    }

    const target = scrollTarget || window;
    const checkIsScrolled = () => {
      setIsScrolled(isIntersected(targetAOptions, targetBOptions));
    };
    checkIsScrolled();
    target.addEventListener('scroll', checkIsScrolled);

    return () => target.removeEventListener('scroll', checkIsScrolled);
  }, [...Object.values(targetAOptions), ...Object.values(targetBOptions), scrollTarget]);

  return isScrolledState;
};

export default useScrollIntersection;
