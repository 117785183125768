import { useSearchParams } from 'next/navigation';
import { useMemo } from 'react';

import { type RootState, useAppSelector } from '@/store';

type withSkeletonProps<TProps> = {
  selector: (state: RootState, props: TProps) => boolean;
  skeleton: React.JSXElementConstructor<TProps>;
};

const withSkeleton = <TProps extends JSX.IntrinsicAttributes & { searchParams: URLSearchParams }>(
  Component: React.JSXElementConstructor<TProps>,
  { selector, skeleton: Skeleton }: withSkeletonProps<TProps>,
) =>
  function SkeletonWrapper(props: TProps) {
    const searchParams = useSearchParams();
    const newProps = useMemo(
      () => ({
        ...props,
        searchParams,
      }),
      [props, searchParams],
    );
    const selectIsLoaded = useMemo(() => (state: RootState) => selector(state, newProps), [newProps]);
    const isLoaded = useAppSelector(selectIsLoaded);
    return isLoaded ? <Component {...(newProps as TProps)} /> : <Skeleton {...(newProps as TProps)} />;
  };

export default withSkeleton;
