import React, { useCallback } from 'react';

import type { RefinementFiltersData } from '@/components/ResultRefinements/RefinementPopover/useRefinementFiltersData';

import Filter from '@/components/Icon/FilterOption';
import { Skeleton } from '@/components/Skeleton';
import UIResource from '@/components/UIResource';
import { selectTravelDatesLabel } from '@/ducks/filters/labels/dates';
import { selectDestinationsLabel } from '@/ducks/filters/labels/destinations';
import { selectFromDate, selectToDate } from '@/ducks/filters/selectors';
import { selectFilteredSailings } from '@/ducks/pages/chooseVoyage/selectors';
import { selectIsFilteringLoading } from '@/ducks/pages/voyagePlanner/selectors';
import { reducedMonthAndYear } from '@/helpers/util/dateUtil';
import useOnKeyDown from '@/hooks/useOnKeyDown';
import { useAppSelector } from '@/store';
import tagmanager from '@/tagmanager';

import './FilterView.scss';

type FilterViewForSMProps = {
  openFilterModal: () => void;
  refinementFiltersData: RefinementFiltersData;
};

const FilterViewForSM = ({ openFilterModal, refinementFiltersData }: FilterViewForSMProps) => {
  const appliedEndDate = useAppSelector(selectToDate);
  const appliedStartDate = useAppSelector(selectFromDate);
  const filteredSailings = useAppSelector(selectFilteredSailings) || [];
  const filteredSailingsLoading = useAppSelector(selectIsFilteringLoading);

  const destinationsLabel = useAppSelector(selectDestinationsLabel);
  const travelDatesLabel = useAppSelector(selectTravelDatesLabel);

  const { destinations, travelDates } = refinementFiltersData;

  const openDestinations = useCallback(
    (event: React.MouseEvent) => {
      destinations.togglePopover(event);
      tagmanager.tracker.voyagesFilter.trackOpenMainFilters({
        name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.DESTINATIONS,
        value: destinationsLabel,
      });
    },
    [destinations, destinationsLabel],
  );

  const openDates = useCallback(
    (event: React.MouseEvent) => {
      travelDates.togglePopover(event);
      tagmanager.tracker.voyagesFilter.trackOpenMainFilters({
        name: tagmanager.trackerConstants.EVENT_LABELS.VOYAGE_FILTERS.TRAVEL_DATES,
        value: `${reducedMonthAndYear(appliedStartDate)}_${reducedMonthAndYear(appliedEndDate)}`,
      });
    },
    [travelDates, appliedStartDate, appliedEndDate],
  );

  const onKeyDownFilter = useOnKeyDown(openFilterModal, { isDefaultPrevented: true });

  return (
    <>
      <div className="FilterText">
        <div className="heading --mobile">
          <div className="refinementText">
            <span
              aria-label="Showing"
              className="text"
              onClick={openFilterModal}
              onKeyDown={onKeyDownFilter}
              role="button"
              tabIndex={0}
            >
              <UIResource id="ChooseVoyage.Results.showing" />
            </span>
            <span className="text action">
              <span className="sailingsCountText">
                {filteredSailingsLoading ? <Skeleton inline width="22px" /> : filteredSailings.length}
              </span>
            </span>
            <span
              aria-label="cruise vacations sailing"
              className="text"
              onClick={openFilterModal}
              onKeyDown={onKeyDownFilter}
              role="button"
              tabIndex={0}
            >
              <UIResource id="ChooseVoyage.Results.cruise.vacations.name" />:
            </span>
            &nbsp;
            <span className="refinementButton">
              <span
                className="text action highlight"
                {...destinations.buttonProps}
                onClick={openDestinations}
                role="button"
                tabIndex={0}
              >
                {destinationsLabel}
              </span>
              <span className="text">,</span>
            </span>
            <span className="refinementButton">
              <span
                className="text action highlight"
                {...travelDates.buttonProps}
                onClick={openDates}
                role="button"
                tabIndex={0}
              >
                {travelDatesLabel}
              </span>
            </span>
          </div>
        </div>
        <div
          aria-label="filter"
          className="filterIconMobile"
          onClick={openFilterModal}
          onKeyDown={onKeyDownFilter}
          role="button"
          tabIndex={0}
        >
          <Filter />
        </div>
      </div>
    </>
  );
};

export default FilterViewForSM;
