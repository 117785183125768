import PropTypes from 'prop-types';
import React, { useCallback } from 'react';

import tagmanager from '@/tagmanager';

import './BlackFridayPromotionBanner.scss';

const BlackFridayPromoBanner = ({ bannerEyebrow, bannerMainPromoBenefit, bannerValueProp }) => {
  const onClick = useCallback(() => tagmanager.tracker.blackFriday.onPromoBannerClick(bannerEyebrow), [bannerEyebrow]);

  return (
    <div className="BFPromoBanner PromotionBannerCard" onClick={onClick} onKeyDown={onClick} role="button" tabIndex={0}>
      <div className="BFPromoBannerHeader">
        <span className="BFEyebrow">{bannerEyebrow}</span>
        <span className="BFBannerMainPromoBenefit">{bannerMainPromoBenefit}</span>
      </div>
      <div className="BFBannerValueProp">{bannerValueProp}</div>
    </div>
  );
};

BlackFridayPromoBanner.propTypes = {
  bannerEyebrow: PropTypes.string.isRequired,
  bannerMainPromoBenefit: PropTypes.string.isRequired,
  bannerValueProp: PropTypes.string.isRequired,
};

export default BlackFridayPromoBanner;
