import { PropTypes } from 'prop-types';
import React from 'react';

import CaretRightImg from '@publicImages/svg/caret-right.svg';

import Svg from '@/components/Svg';

const CaretRight = ({ className, onClick }) => (
  <span className="Icon CaretRight">
    <Svg onClick={onClick} src={CaretRightImg} svgClassName={className} />
  </span>
);

CaretRight.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func,
};

CaretRight.defaultProps = {
  className: '',
  onClick: () => {},
};

export default CaretRight;
