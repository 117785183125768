import type ReactPlayer from 'react-player';

import { type RefObject, useCallback, useEffect, useState } from 'react';

import Mute from '@/components/Icon/playback/Mute';
import MuteSmall from '@/components/Icon/playback/MuteSmall';
import Pause from '@/components/Icon/playback/Pause';
import PauseSmall from '@/components/Icon/playback/PauseSmall';
import Play from '@/components/Icon/playback/Play';
import PlaySmall from '@/components/Icon/playback/PlaySmall';
import { useIsMobile } from '@/hooks/useIsDevice';

import './ImageVideo.scss';

type SizzleReelsControlsProps = {
  videoRef: RefObject<ReactPlayer | null>;
};

const SizzleReelsControls = ({ videoRef }: SizzleReelsControlsProps) => {
  const [paused, setPaused] = useState(true);
  const [muted, setMuted] = useState(false);
  const isMobile = useIsMobile();

  useEffect(() => {
    if (videoRef.current) {
      setMuted(videoRef.current.props?.muted || false);
      setPaused(videoRef.current.props?.paused || false);
    }
  }, [videoRef, setPaused, setMuted]);

  const handlePauseClick = useCallback(() => {
    if (videoRef.current) {
      setPaused(true);
      (videoRef.current.getInternalPlayer() as HTMLMediaElement).pause();
    }
  }, [videoRef, setPaused]);

  const handlePlayClick = useCallback(() => {
    if (videoRef.current) {
      setPaused(false);
      (videoRef.current.getInternalPlayer() as HTMLMediaElement).play();
    }
  }, [videoRef, setPaused]);

  return (
    <div className="ImageVideo__SizzleReelsControls">
      {isMobile ? (
        paused ? (
          <PlaySmall onClick={handlePlayClick} />
        ) : (
          <PauseSmall onClick={handlePauseClick} />
        )
      ) : paused ? (
        <Play onClick={handlePlayClick} />
      ) : (
        <Pause onClick={handlePauseClick} />
      )}

      {muted && (isMobile ? <MuteSmall /> : <Mute />)}
    </div>
  );
};

export default SizzleReelsControls;
