import { useSearchParams } from 'next/navigation';
import React from 'react';

import { selectPriceLabel } from '@/ducks/filters/labels/price';
import { FILTER_KEY } from '@/ducks/filters/types';
import { checkIfFilterOverridden } from '@/ducks/filters/utils';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const PriceFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const label = useAppSelector(selectPriceLabel);
  const searchParams = useSearchParams();

  return label &&
    (checkIfFilterOverridden(FILTER_KEY.priceMax, searchParams) ||
      checkIfFilterOverridden(FILTER_KEY.priceMin, searchParams)) ? (
    <SelectedFilterChip
      filterKeys={[FILTER_KEY.priceMax, FILTER_KEY.priceMin, FILTER_KEY.priceType]}
      label={label}
      onOpenFilterModal={onOpenFilterModal}
    />
  ) : null;
};

export default PriceFilterChip;
