import cn from 'classnames';

import type { TUIResourceId } from '@/ducks/common/resources';

import Arrow from '@/components/Icon/Arrow';
import UIResource from '@/components/UIResource';

type MainFilterButtonProps = {
  disabled?: boolean;
  label: string;
  onClick: (event: React.MouseEvent) => void;
  titleId: TUIResourceId;
};

const MobileFilterButton = ({ disabled, label, onClick, titleId }: MainFilterButtonProps) => (
  <button
    className={cn('filterRefinementMobileView__mainFilter__button', { '-disabled': disabled })}
    onClick={disabled ? undefined : onClick}
  >
    <span className="filterRefinementMobileView__mainFilter__button__label">
      <UIResource id={titleId} />
      <span className="filterRefinementMobileView__mainFilter__button__label__options">{label}</span>
    </span>
    <span className="filterRefinementMobileView__mainFilter__button__arrow">
      <Arrow />
    </span>
  </button>
);

export default MobileFilterButton;
