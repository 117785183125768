import { Skeleton } from '@/components/Skeleton';

import './SailingCard.scss';

const SailingCardSkeleton = () => (
  <div className="SailingCard">
    <div className="iconYear">
      <Skeleton inline width={16} />
      <Skeleton inline width={28} />
    </div>
    <div className="startEndDate">
      <Skeleton width={36} />
    </div>
    <span className="priceFromLabel">
      {' '}
      <Skeleton inline width={21} />{' '}
    </span>
    <span className="price">
      <Skeleton inline width={36} />
    </span>
  </div>
);

export default SailingCardSkeleton;
