import { useMemo } from 'react';

import { QuickSearchCard } from './Card';

export const QuickSearchCardsSkeleton = ({ cardsCount = 4 }) => {
  const cardsNumbers = useMemo(() => {
    return new Array(cardsCount).fill(0).map((v, i) => i);
  }, [cardsCount]);

  return (
    <>
      {cardsNumbers.map((number) => {
        return <QuickSearchCard isLoading key={number} />;
      })}
    </>
  );
};
