import { useEffect, useState } from 'react';

import type { TWhatsIncluded } from '@/components/WhatsIncluded/WhatsIncludedTypes';

import { fetchWhatsIncludedDataAction } from '@/actions';
import { selectIsWhatsIncludedLoaded, selectWhatsIncluded } from '@/ducks/pages/voyagePlanner/selectors';
import { getVWOFeatureVariableValue } from '@/helpers/vwo/getVWOFeatureVariableValue';
import useOpenable from '@/hooks/useOpenable';
import { useAppDispatch, useAppSelector } from '@/store';
import { VWOFlag, WHATS_INCLUDED_KEYS } from '@/types/vwo';

import WhatsIncludedModal from './Modal';
import WhatsIncludedContent from './WhatsIncludedContent';

import './WhatsIncluded.scss';

interface WhatsIncludedProps {
  vwoFeatureVariableKey: WHATS_INCLUDED_KEYS;
}

const WhatsIncluded = ({ vwoFeatureVariableKey }: WhatsIncludedProps) => {
  const dispatch = useAppDispatch();
  const vwoFeatureKey = VWOFlag.WHATS_INCLUDED;

  const { close, isOpened: isModalOpen, open } = useOpenable();

  const whatsIncludedData = useAppSelector<TWhatsIncluded[]>(selectWhatsIncluded);
  const whatsIncludedDataLoaded = useAppSelector<TWhatsIncluded[]>(selectIsWhatsIncludedLoaded);
  const [sortedFeatures, setSortedFeatures] = useState<TWhatsIncluded[]>([]);
  const [isWhatsIncludedEnabled, setIsWhatsIncludedEnabled] = useState(false);

  useEffect(() => {
    if (isWhatsIncludedEnabled && !whatsIncludedDataLoaded) {
      dispatch(fetchWhatsIncludedDataAction());
    } else {
      whatsIncludedData?.length &&
        setSortedFeatures([...whatsIncludedData].sort((a, b) => a.navigationOrder - b.navigationOrder));
    }
  }, [whatsIncludedData, isWhatsIncludedEnabled, whatsIncludedDataLoaded, dispatch]);

  useEffect(() => {
    getVWOFeatureVariableValue(vwoFeatureKey, vwoFeatureVariableKey).then(
      (isEnabled) => isEnabled && setIsWhatsIncludedEnabled(true),
    );
  }, [vwoFeatureKey, vwoFeatureVariableKey]);

  if (!isWhatsIncludedEnabled) {
    return null;
  }

  return (
    <>
      <WhatsIncludedContent onBannerClick={open} sortedFeatures={sortedFeatures} />
      <WhatsIncludedModal features={sortedFeatures} isOpen={isModalOpen} onDismiss={close} />
    </>
  );
};

export default WhatsIncluded;
