import { PropTypes } from 'prop-types';
import React from 'react';

import CloseWithoutBackgroundImg from '@publicImages/svg/close-without-bg.svg';

import Svg from '@/components/Svg';

const CloseWithoutBackground = ({ className }) => (
  <span className="Icon Close">
    <Svg src={CloseWithoutBackgroundImg} svgClassName={className} />
  </span>
);

CloseWithoutBackground.propTypes = {
  className: PropTypes.string,
};

CloseWithoutBackground.defaultProps = {
  className: '',
};

export default CloseWithoutBackground;
