'use client';

import { useSearchParams } from 'next/navigation';

import compact from 'lodash/compact';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import omitBy from 'lodash/omitBy';
import qs from 'query-string';

import searchParamsAsObject from '@/helpers/url/searchParamsAsObject';

type Props = {
  omitKeys?: (false | null | string | undefined)[];
  pathname: string;
  query?: Record<string, boolean | number | readonly string[] | string>;
};

export const useKeepSearch = ({ omitKeys = [], pathname, query = {} }: Props) => {
  const params = useSearchParams();
  const paramsObj = searchParamsAsObject(params);
  const filteredPreviousQuery = omit(paramsObj, compact(omitKeys));
  const filteredQuery = omitBy(query, isEmpty);
  const mergedQuery = {
    ...filteredPreviousQuery,
    ...filteredQuery,
  };
  return `${pathname}?${qs.stringify(mergedQuery)}`;
};
