import { useMemo } from 'react';

import { useUIResourcePool } from '@/ducks/common/resources';
import { FiltersPriceType } from '@/infra/types/common/filters';

export const usePriceTypeOptions = () => {
  const { perCabin, perSailor, sailorPerNight } = useUIResourcePool({
    perCabin: 'VoyageFilter.PerCabin',
    perSailor: 'VoyageFilter.PerSailor',
    sailorPerNight: 'VoyageFilter.SailorPerNight',
  });
  return useMemo(
    () =>
      [
        {
          enabled: true,
          label: perCabin,
          value: FiltersPriceType.perCabin,
        },
        {
          enabled: true,
          label: perSailor,
          value: FiltersPriceType.perSailor,
        },
        {
          enabled: true,
          label: sailorPerNight,
          value: FiltersPriceType.sailorPerNight,
        },
      ] as const,
    [perCabin, perSailor, sailorPerNight],
  );
};
