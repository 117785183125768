import { useRef } from 'react';

import useGetRefinementPopoverProps from '@/components/ResultRefinements/RefinementPopover/useGetRefinementPopoverProps';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
import { useAppSelector } from '@/store';

import DateRefinement from './index';

type UseDateRefinementPopoverProps = {
  isMobile: boolean;
  onChangeStatus: (isOpen: boolean) => void;
};

const useDateRefinementPopover = ({ isMobile, onChangeStatus }: UseDateRefinementPopoverProps) => {
  const disabled = useAppSelector(selectMgm).isReferredBooker;
  const buttonRef = useRef<HTMLElement | null>(null);

  const popoverProps = useGetRefinementPopoverProps({
    buttonRef,
    focusTrapDisableEvents: ['x-arrows', 'y-arrows'],
    isMobile,
    onChangeStatus,
    placement: 'bottom-end',
  });

  return {
    ...popoverProps,
    buttonProps: popoverProps.getReferenceProps(/* { className: ... } */),
    disabled,
    popover: <DateRefinement isMobile={isMobile} key="DateRefinementKey" popoverProps={popoverProps} />,
  };
};

export default useDateRefinementPopover;
