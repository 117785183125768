import React, { useCallback, useEffect, useMemo, useState } from 'react';

import cn from 'classnames';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';

import type { NullablePartial } from '@/types/common';

import ArrowLeft from '@/components/Icon/ArrowLeft';
import Close from '@/components/Icon/Close';
import FilterDestination from '@/components/Icon/filterDestination';
import { ListBox, ListItem } from '@/components/ListBox';
import { Tabs, TabsContent, TabsList, TabsTrigger } from '@/components/Tabs';
import UIResource from '@/components/UIResource';
import { FormCheck } from '@/components/elements';
import { setFilters } from '@/ducks/filters/setters';
import { FILTER_KEY, type FiltersTypes } from '@/ducks/filters/types';
import uniq from '@/helpers/uniq';
import { setTrackingDestinationFilter, trackResetFilters } from '@/helpers/util/tracking';
import { useAppDispatch, useAppSelector } from '@/store';

import type { RefinementPopoverProps } from '../RefinementPopover/useGetRefinementPopoverProps';

import RefinementButtons from '../RefinementButtons';
import RefinementPopover from '../RefinementPopover';
import DestinationItem from './DestinationItem';
import DestinationRegion from './DestinationRegion';
import { selectDestinationsFilterData } from './selectors';
import {
  type AllItemsIds,
  type DestinationCountryItem,
  type DestinationItineraryItem,
  DestinationType,
  type DestinationRegion as Region,
} from './types';
import {
  addItemsIds,
  getAppliedItemsIds,
  getCheckedItemsIds,
  getEmptyItemsIds,
  getItemLabel,
  getSafeId,
  isItemIdSelected,
  removeItemsIds,
  useGetAppliedDestinationType,
} from './utils';

import './DestinationRefinement.scss';

type DestinationRefinementProps = {
  isMobile?: boolean;
  popoverProps: RefinementPopoverProps;
};

const DestinationRefinement = (props: DestinationRefinementProps) => {
  const dispatch = useAppDispatch();

  const {
    isMobile = false,
    popoverProps,
    popoverProps: { isOpen, togglePopover },
  } = props;

  const [showItineraries, setShowItineraries] = useState(false);
  const showRegions = !showItineraries;
  const [regionsAriaLiveMessage, setRegionsAriaLiveMessage] = useState('');
  const [itemsAriaLiveMessage, setItemsAriaLiveMessage] = useState('');

  const data = useAppSelector(selectDestinationsFilterData);
  const appliedDestinationType = useGetAppliedDestinationType();

  const getAppliedData = useCallback(() => {
    return {
      appliedDestinationType,
      appliedItemsIds: getAppliedItemsIds(data),
      appliedRegionId:
        data[appliedDestinationType].find((region) => region.selected)?.id || data[appliedDestinationType][0]!.id,
    };
  }, [data, appliedDestinationType]);

  const { appliedItemsIds, appliedRegionId } = useMemo(() => getAppliedData(), [getAppliedData]);

  const [destinationType, setDestinationType] = useState(appliedDestinationType);
  const [selectedRegionId, setSelectedRegionId] = useState(appliedRegionId);
  const [allItemsIds, setAllItemsIds] = useState<AllItemsIds>(appliedItemsIds);

  const getRegions = useCallback((type?: DestinationType) => data[type || destinationType], [data, destinationType]);
  const getRegion = useCallback(
    (id?: string, type?: DestinationType) => {
      const regions = getRegions(type);
      return id ? regions.find((region) => region.id === id)! : regions[0]!;
    },
    [getRegions],
  );
  const selectedRegion = useMemo(() => getRegion(selectedRegionId), [getRegion, selectedRegionId]);

  const resetToAppliedData = useCallback(() => {
    const { appliedDestinationType, appliedItemsIds, appliedRegionId } = getAppliedData();
    setDestinationType(appliedDestinationType);
    setSelectedRegionId(appliedRegionId);
    setAllItemsIds(appliedItemsIds);
  }, [getAppliedData]);

  useEffect(() => {
    resetToAppliedData();
    setShowItineraries(false);
  }, [resetToAppliedData, isOpen]);

  const isWholeChecked = useCallback((region: Region, destinationType: DestinationType, allItemsIds: AllItemsIds) => {
    return region && region.allItemsIds.length === getCheckedItemsIds(allItemsIds, region.id, destinationType).length;
  }, []);

  const processRegionsAriaLiveMessage = useCallback(
    (regionId: string, _allItemsIds: AllItemsIds) => {
      const region = getRegions().find((r) => r.id === regionId)!;
      const announceLabel = `${region.label}, checkbox`;
      const isAllChecked = isWholeChecked(region, destinationType, _allItemsIds);
      const checkedIds = getCheckedItemsIds(_allItemsIds, region.id, destinationType);
      if (isAllChecked) {
        setRegionsAriaLiveMessage(`All checked, ${announceLabel}`);
      } else {
        setRegionsAriaLiveMessage(
          checkedIds.length > 0 ? `${checkedIds.length} checked, ${announceLabel}` : `Not checked, ${announceLabel}`,
        );
      }
    },
    [isWholeChecked, getRegions, destinationType],
  );

  const processItemsAriaLiveMessage = useCallback(
    (itemId: string, _allItemsIds: AllItemsIds) => {
      const label = getItemLabel(itemId, selectedRegion.items, destinationType);
      const announceLabel = `${label}, checkbox`;
      const checked = isItemIdSelected(_allItemsIds, destinationType, selectedRegion.id, itemId);
      setItemsAriaLiveMessage(checked ? `Checked, ${announceLabel}` : `Not checked, ${announceLabel}`);
    },
    [destinationType, selectedRegion],
  );

  const onRegionCheck = useCallback(
    (regionId: string) => {
      const region = getRegion(regionId);
      let newIds;
      if (!isEmpty(allItemsIds[destinationType]?.[regionId])) {
        newIds = removeItemsIds(allItemsIds, destinationType, allItemsIds[destinationType]![regionId]!);
        setAllItemsIds(newIds);
      } else {
        newIds = addItemsIds(data, allItemsIds, destinationType, region.allItemsIds);
        setAllItemsIds(newIds);
      }
      processRegionsAriaLiveMessage(regionId, newIds);
    },
    [getRegion, allItemsIds, destinationType, processRegionsAriaLiveMessage, data],
  );

  const onRegionSelect = useCallback(
    (regionId: string) => {
      setSelectedRegionId(regionId);
      if (isMobile) {
        setShowItineraries(true);
      }
    },
    [isMobile],
  );

  const onDestinationTypeChange = useCallback((value: string) => {
    setDestinationType(value as DestinationType);
  }, []);

  const onItemClick = useCallback(
    (itemId: string) => {
      let newIds;
      if (isItemIdSelected(allItemsIds, destinationType, selectedRegionId, itemId)) {
        newIds = removeItemsIds(allItemsIds, destinationType, [itemId]);
        setAllItemsIds(newIds);
      } else {
        newIds = addItemsIds(data, allItemsIds, destinationType, [itemId]);
        setAllItemsIds(newIds);
      }
      processItemsAriaLiveMessage(itemId, newIds);
    },
    [allItemsIds, destinationType, selectedRegionId, data, processItemsAriaLiveMessage],
  );

  const safeSelectedRegionId = getSafeId(selectedRegionId);

  const isWholeCheckedById = useCallback(
    (regionId: string, destinationType: DestinationType) => {
      const region = getRegion(regionId, destinationType);
      return isWholeChecked(region, destinationType, allItemsIds);
    },
    [getRegion, isWholeChecked, allItemsIds],
  );

  const onSubmit = useCallback(
    (event: React.UIEvent) => {
      event.stopPropagation();

      const regionsIds = Object.keys(allItemsIds[destinationType] || {}).filter(
        (id) => !isEmpty(allItemsIds[destinationType]?.[id] || []),
      );

      const itemsIds = uniq(
        regionsIds.reduce<string[]>((ids: string[], id: string) => {
          const itemsIds = allItemsIds[destinationType]?.[id] || [];

          if (getRegion(id)?.allItemsIds === itemsIds) {
            // when region is "all selected" not necessary to add it's items to destPackages/destPorts
            // adding region id just to destPackagesRegions/destPortsRegions is enough
            return ids;
          }

          return [...ids, ...itemsIds];
        }, []),
      );

      let filters: NullablePartial<FiltersTypes> = {
        [FILTER_KEY.destPackages]: destinationType === DestinationType.ITINERARIES ? itemsIds : [],
        [FILTER_KEY.destPackagesRegions]: destinationType === DestinationType.ITINERARIES ? regionsIds : [],
        [FILTER_KEY.destPorts]: destinationType === DestinationType.PORTS_OF_CALL ? itemsIds : [],
        [FILTER_KEY.destPortsRegions]: destinationType === DestinationType.PORTS_OF_CALL ? regionsIds : [],
      };

      if (!allItemsIds[destinationType] || isEmpty(allItemsIds[destinationType])) {
        filters = { ...filters, [FILTER_KEY.voyageIds]: null };
      }

      setFilters(filters);

      togglePopover?.(event as React.MouseEvent);
      dispatch(setTrackingDestinationFilter(isWholeCheckedById));
    },
    [isWholeCheckedById, destinationType, getRegion, togglePopover, dispatch, allItemsIds],
  );

  const onRegionsKeyDown = useCallback(
    (e: React.KeyboardEvent) => {
      if (e.key === 'ArrowRight') {
        e.preventDefault();
        document
          .getElementById(destinationType === DestinationType.ITINERARIES ? 'itineraries-list' : 'ports-of-call-list')
          ?.focus();
      }
    },
    [destinationType],
  );

  const onItemsKeyDown = useCallback((e: React.KeyboardEvent) => {
    if (e.key === 'ArrowLeft') {
      e.preventDefault();
      document.getElementById('regions-list')?.focus();
    }
  }, []);

  const onReset = useCallback((data?: { resetAll?: boolean }) => {
    if (!isEmpty(data) && !data.resetAll) {
      trackResetFilters('destination');
    }

    setAllItemsIds(getEmptyItemsIds());
  }, []);

  const onGoBackClick = useCallback(
    (event: React.MouseEvent) => {
      if (isMobile && showItineraries) {
        setShowItineraries(false);
      } else {
        togglePopover?.(event, { closedByBackButton: true });
      }
    },
    [showItineraries, togglePopover, isMobile],
  );

  const onCloseClick = useCallback(
    (event: React.MouseEvent) => {
      togglePopover?.(event);
    },
    [togglePopover],
  );

  const isDataApplied = useMemo(() => !isEqual(getAppliedItemsIds(data), getEmptyItemsIds()), [data]);
  const isDataChanged = useMemo(() => !isEqual(getAppliedItemsIds(data), allItemsIds), [data, allItemsIds]);

  const detailsClasses = cn('DestinationRefinement__Details', { showItineraries });
  const regionsListClasses = cn('filterRegionsList', { showRegions: showRegions });
  const itemsListClassNames = cn('filterItemsList', { showItineraries: showItineraries });
  const mobileRegionTitleClasses = cn('mobileRegionTitle', { toggle: showItineraries });
  const headerClasses = cn('DestinationRefinement__Header');
  const toggleClasses = cn('DestinationRefinement__filterToggle', { toggle: showItineraries });

  return (
    <RefinementPopover
      {...popoverProps}
      className="destination-refinement-filter-popup"
      dialogLabel="Destination Options"
      id="DestinationRefinement"
    >
      <div className="DestinationRefinement">
        <section className={headerClasses}>
          <div className="filter-popup__header">
            {isMobile && (
              <>
                <button
                  aria-label="Go back"
                  className="filter-popup__header__button filter-popup__header__button-back"
                  onClick={onGoBackClick}
                >
                  <ArrowLeft />
                </button>
                <button
                  aria-label="Close"
                  className="filter-popup__header__button filter-popup__header__button-close"
                  onClick={onCloseClick}
                >
                  <Close />
                </button>
              </>
            )}

            <span className="filter-popup__header__title">
              <FilterDestination />
              <span className="filter-popup__header__title__title-text">
                <UIResource id="DestinationRefinement.Book" />
              </span>
            </span>
          </div>

          <div className="filterDescription">
            <div className={mobileRegionTitleClasses}>
              <span className="regionTitleMobile">{selectedRegion?.label}</span>
              <div
                className={cn('allItineraryCheck', {
                  active:
                    !isWholeChecked(selectedRegion, destinationType, allItemsIds) &&
                    getCheckedItemsIds(allItemsIds, selectedRegionId, destinationType).length > 0,
                })}
              >
                <FormCheck
                  checked={isWholeChecked(selectedRegion, destinationType, allItemsIds)}
                  id={`ALL-${selectedRegionId}`}
                  name="allCheck"
                  onChange={() => onRegionCheck(selectedRegionId)}
                  tabIndex={showItineraries ? 0 : -1}
                  type="checkbox"
                >
                  <span className="allItineraryCheckText">
                    <UIResource
                      id="Destination.allCheckbox"
                      values={{
                        count: selectedRegion?.allItemsIds.length,
                        toggleValue: destinationType,
                      }}
                    />
                  </span>
                </FormCheck>
              </div>
            </div>
          </div>
        </section>

        <Tabs className={detailsClasses} onValueChange={onDestinationTypeChange} value={destinationType}>
          <TabsList className={toggleClasses} tabIndex={-1}>
            <TabsTrigger
              tabIndex={destinationType === DestinationType.ITINERARIES ? 0 : -1}
              value={DestinationType.ITINERARIES}
            >
              <UIResource id="RegionRefinements.itineraries" />
            </TabsTrigger>
            <TabsTrigger
              tabIndex={destinationType === DestinationType.PORTS_OF_CALL ? 0 : -1}
              value={DestinationType.PORTS_OF_CALL}
            >
              <UIResource id="RegionRefinements.port" />
            </TabsTrigger>
          </TabsList>

          <div className={regionsListClasses}>
            <div className="filterTitle">
              <UIResource id="Book CTA" />
            </div>
            <div aria-live="polite" className="sr-only" role="status">
              {regionsAriaLiveMessage}
            </div>
            <div aria-live="polite" className="sr-only" role="status">
              {itemsAriaLiveMessage}
            </div>
            <ListBox
              aria-controls="itineraries-list ports-of-call-list"
              id="regions-list"
              onKeyDown={onRegionsKeyDown}
              onPressEnterItem={onRegionCheck}
              onSelectItem={onRegionSelect}
              value={selectedRegionId}
            >
              {getRegions().map((region) => (
                <ListItem key={`region ${region.id} ${destinationType}`} value={region.id}>
                  <DestinationRegion
                    checkedIds={getCheckedItemsIds(allItemsIds, region.id, destinationType)}
                    disableSwitchToRegionOnSelect={isMobile}
                    id={`dest_region_${getSafeId(region.id)}_${destinationType}`}
                    isWholeChecked={isWholeChecked(region, destinationType, allItemsIds)}
                    onCheck={onRegionCheck}
                    region={region}
                    selected={selectedRegionId === region.id}
                  />
                </ListItem>
              ))}
            </ListBox>
          </div>

          <TabsContent
            className={itemsListClassNames}
            preserveNativeAriaLabelledby
            role="tabpanel"
            tabIndex={-1}
            value={DestinationType.ITINERARIES}
          >
            <div className="filterTitle" id="itineraries-list-label">
              {selectedRegion.itemsCount} {selectedRegion.label} <UIResource id="Destination.itineraries" />
            </div>
            <ListBox
              aria-labelledby="itineraries-list-label"
              id="itineraries-list"
              onKeyDown={onItemsKeyDown}
              onPressEnterItem={onItemClick}
            >
              {(selectedRegion.items as DestinationItineraryItem[]).map((item, index) => (
                <ListItem key={`${selectedRegion.id} ${item.id} - ${index}`} value={item.id}>
                  <DestinationItem
                    checked={isItemIdSelected(allItemsIds, destinationType, selectedRegion.id, item.id)}
                    id={`item-${safeSelectedRegionId}-${item.id}`}
                    item={item}
                    onClick={onItemClick}
                  />
                </ListItem>
              ))}
            </ListBox>
          </TabsContent>

          <TabsContent
            className={itemsListClassNames}
            preserveNativeAriaLabelledby
            role="tabpanel"
            tabIndex={-1}
            value={DestinationType.PORTS_OF_CALL}
          >
            <div className="filterTitle" id="ports-of-call-list-label">
              {selectedRegion.itemsCount} {selectedRegion.label} <UIResource id="Destination.ports" />
            </div>
            <ListBox
              aria-labelledby="ports-of-call-list-label"
              id="ports-of-call-list"
              onKeyDown={onItemsKeyDown}
              onPressEnterItem={onItemClick}
            >
              {(selectedRegion.items as DestinationCountryItem[]).map((country) => (
                <React.Fragment key={`${selectedRegion.id} ${country.label}`}>
                  <div aria-hidden="true" className="countryName">
                    {country.label}
                  </div>
                  {country.items?.map((item, index) => (
                    <ListItem
                      className={cn({ firstPort: index === 0 })}
                      key={`${selectedRegion.id} ${item.id} ${country.label}`}
                      value={item.id}
                    >
                      <DestinationItem
                        checked={isItemIdSelected(allItemsIds, destinationType, selectedRegion.id, item.id)}
                        id={`item-${safeSelectedRegionId}-${item.id}`}
                        item={item}
                        onClick={onItemClick}
                      />
                    </ListItem>
                  ))}
                </React.Fragment>
              ))}
            </ListBox>
          </TabsContent>
        </Tabs>
      </div>
      <RefinementButtons
        isDataApplied={isDataApplied}
        isDataChanged={isDataChanged}
        onApply={onSubmit}
        onReset={onReset}
        withBottomGlow
      />
    </RefinementPopover>
  );
};

export default DestinationRefinement;
