import React from 'react';

import { FloatingFocusManager, FloatingPortal } from '@floating-ui/react';
import cn from 'classnames';

import useFocusTrap from '@/ducks/a11y/hooks/useFocusTrap';

import { type RefinementPopoverProps } from './useGetRefinementPopoverProps';

import './Filter.scss';

type TRefinementPopoverProps = {
  children: React.ReactElement | React.ReactElement[];
  className?: string;
  dialogLabel?: string;
  id?: string;
} & RefinementPopoverProps;

const RefinementPopover = ({
  children,
  className,
  context,
  dialogLabel,
  floatingStyles,
  focusTrapDisableEvents,
  getFloatingProps,
  id,
  isOpen,
  refs,
  status,
}: TRefinementPopoverProps) => {
  useFocusTrap({
    disableEvents: focusTrapDisableEvents,
    element: refs.floating?.current,
    isOpened: isOpen,
  });

  return (
    <>
      {isOpen && (
        <FloatingPortal>
          <FloatingFocusManager context={context} initialFocus={refs.floating}>
            <div
              aria-label={dialogLabel}
              data-status={status}
              id={id}
              ref={refs.setFloating}
              style={{ ...floatingStyles, display: isOpen ? 'block' : 'none' }}
              {...getFloatingProps({ className: cn('filter-popup', className) })}
              aria-modal="true"
              role="dialog"
            >
              {children}
            </div>
          </FloatingFocusManager>
        </FloatingPortal>
      )}
    </>
  );
};

export default RefinementPopover;
