'use client';

import PropTypes from 'prop-types';
import React from 'react';

import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import reduce from 'lodash/reduce';
import throttle from 'lodash/throttle';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { clearCabinCategoriesDataAction } from '@/actions/voyagePlanner/chooseCabin/fetchCabinCategories';
import { clearInvoiceDataAction } from '@/actions/voyagePlanner/summary/calculateInvoice';
import { payInFullPromoAppliedAction } from '@/actions/voyagePlanner/summary/payInFullPromoApply';
import FilterOption from '@/components/Icon/FilterOption';
import { getAccessKey } from '@/ducks/accessKeys';
import { selectLookup } from '@/ducks/common/selectors';
import { selectSettings } from '@/ducks/common/settings';
import {
  selectCabinType,
  selectCurrencyCode,
  selectIsSuitableCabinType,
  selectPriceType,
  selectSailors,
} from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import {
  selectDefaultPackages,
  selectFilteredPackages,
  selectFilteredSailings,
  selectMainPackages,
} from '@/ducks/pages/chooseVoyage/selectors';
import { getCelebrationsInfoSession } from '@/ducks/programs/celebrations/actions';
import { exitReferredBookerFlowAction } from '@/ducks/programs/mgm/actions';
import { selectMgm } from '@/ducks/programs/mgm/selectors';
import { routes } from '@/ducks/routes';
import { resetTravelParty } from '@/ducks/travelParty/slice';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';
import { removePaymentPaymentOption, removePaymentReservationNumber } from '@/helpers/managers/payment';
import { removeSessionStorageValue, removeStoredValue } from '@/helpers/util/storage';
import { trackSailorId } from '@/helpers/util/tracking';
import { withIsDesktop } from '@/hocs/withIsDevice';
import withRouter from '@/hocs/withRouter';
import withSearchParams from '@/hocs/withSearchParams';
import tagmanager from '@/tagmanager';
import { Headline } from '@/widgets/Promo/Headline';
import { QuickSearchCards } from '@/widgets/Promo/QuickSearchCards';

import PackageItineraryResults from './PackageItineraryResults';

import './PackageResults.scss';

class ItineraryResults extends React.Component {
  isPackageInView = () => {
    if (document.documentElement.scrollTop === 0) {
      return false;
    }

    const windowHeight = window.innerHeight || document.documentElement.clientHeight;

    return reduce(
      document.getElementsByClassName('PackageItineraryResults__body'),
      (_, card) => {
        const rect = card.getBoundingClientRect();
        if (rect.top > 0 || rect.top > windowHeight) {
          return false;
        }
        return true;
      },
      false,
    );
  };

  onSearch = () => {
    if (this.props?.isDesktop) {
      window.scrollTo(0, 0);
    } else {
      this.setState({ mobileSearchAgainClickedFlag: !this.state.mobileSearchAgainClickedFlag });
    }
    this.setState({ searchAgain: false });
    tagmanager.tracker.voyagesFilter.trackReApplyFilters();
  };

  constructor(props) {
    super(props);
    this.state = {
      isChangedPackagesEventSent: false,
      isReady: false,
      searchAgain: false,
    };

    this.handleScroll = throttle(this.handleScroll.bind(this), 150);
  }

  componentDidMount() {
    const { appliedSailors, celebrations } = this.props;

    // TODO: move to cookies
    const celebrationSessionData = getCelebrationsInfoSession();

    if (
      (celebrationSessionData && celebrationSessionData?.newReferral === false) ||
      celebrations?.params?.newReferral === false
    ) {
      const celebrationData =
        celebrations?.params?.voyageCode && celebrations?.params?.voyageId
          ? celebrations?.params
          : celebrationSessionData;
      const { packageCode, shipCode, voyageId } = celebrationData;
      routes.planner.preCheckOut.go({
        keepSearch: true,
        packageCode,
        sailors: appliedSailors,
        shipCode,
        voyageId,
      });
      return;
    }

    const { actions } = this.props;

    window.scrollTo(0, 0);

    window.addEventListener('scroll', this.handleScroll);

    removeStoredValue('primarySailorError');

    removeSessionStorageValue('primarySailor');
    removePaymentReservationNumber();
    removePaymentPaymentOption();
    removeSessionStorageValue('add_flights');

    actions.resetTravelParty();
    actions.payInFullPromoAppliedAction(false);
    actions.clearInvoiceDataAction();
    actions.clearCabinCategoriesDataAction();

    trackSailorId();
  }

  componentDidUpdate(prevProps) {
    const { dispatch, filteredPackages, filteredSailings } = this.props;
    const { isChangedPackagesEventSent } = this.state;

    const areSailingsChanged = !isEqual(filteredSailings, prevProps.filteredSailings);
    const isFetchedEventSent = !isEmpty(filteredSailings) && !isChangedPackagesEventSent;

    if (areSailingsChanged || isFetchedEventSent) {
      this.setState({
        isChangedPackagesEventSent: true,
      });
      tagmanager.observer.trackingEventsObserver.notify({
        data: {
          dispatch,
          packages: filteredPackages,
        },
        name: tagmanager.observer.TRACKING_EVENTS_FOR_OBSERVER.CHOOSE_SAILING_PAGE_LOADED,
      });
    }
  }

  componentWillUnmount() {
    window.removeEventListener('scroll', this.handleScroll);
  }

  handleScroll() {
    const isSearchAgainBtnVisible = this.isPackageInView();
    this.setState({ searchAgain: isSearchAgainBtnVisible });
    const isGlobalNavVisible = document.getElementsByClassName('GlobalNavigation')?.[0]?.classList?.contains('-hidden');
    const searchAgainCta = document.getElementsByClassName('PackageItineraryResults__searchAgain')?.[0];
    if (!searchAgainCta) {
      return;
    }
    if (isGlobalNavVisible) {
      searchAgainCta.classList.remove('--down');
    } else {
      searchAgainCta.classList.add('--down');
    }
  }

  render() {
    const { mobileSearchAgainClickedFlag, searchAgain } = this.state;

    return (
      <div>
        <section className="NewVoyagePlanner">
          {searchAgain && (
            <div className="PackageItineraryResults__searchAgain">
              <div className="PackageItineraryResults__searchAgain__container">
                <div className="PackageItineraryResults__searchAgain__container__icon">
                  <FilterOption />
                </div>
                <div
                  className="PackageItineraryResults__searchAgain__container__text"
                  onClick={() => this.onSearch()}
                  onKeyDown={() => this.onSearch()}
                  role="button"
                  tabIndex={0}
                >
                  <span aria-label="hidden" />
                  <FormattedMessage defaultMessage="Search Again" id="ChooseVoyage.SearchAgain.button" />
                </div>
              </div>
            </div>
          )}
          <PackageItineraryResults
            headlineWithAdvertCardList={
              <Headline>
                <QuickSearchCards />
              </Headline>
            }
            mobileSearchAgainClickedFlag={mobileSearchAgainClickedFlag}
          />
        </section>
      </div>
    );
  }
}

ItineraryResults.propTypes = {
  actions: PropTypes.shape({
    clearCabinCategoriesDataAction: PropTypes.func,
    clearInvoiceDataAction: PropTypes.func,
    payInFullPromoAppliedAction: PropTypes.func,
    resetFilters: PropTypes.func,
    resetTravelParty: PropTypes.func,
    setPriceTypeForPackages: PropTypes.func,
  }).isRequired,
  appliedCurrency: PropTypes.string.isRequired,
  appliedPriceType: PropTypes.string,
  celebrations: PropTypes.objectOf({
    isCelebrations: PropTypes.bool,
    params: {
      pkgCode: PropTypes.string,
      shipCode: PropTypes.string,
      voyageCode: PropTypes.string,
    },
  }),
  configServiceData: PropTypes.shape({
    featureFlags: PropTypes.shape({}),
  }),
  dispatch: PropTypes.func.isRequired,
  filteredSailings: PropTypes.arrayOf(PropTypes.shape([])).isRequired,
  filtersOptions: PropTypes.shape({
    loaded: PropTypes.bool,
    regions: PropTypes.arrayOf(PropTypes.shape({})),
  }).isRequired,
  itineraryDetails: PropTypes.shape({}),
  lookup: PropTypes.shape({
    data: PropTypes.shape({}),
  }),
};

ItineraryResults.defaultProps = {
  appliedPriceType: '',
  celebrations: {},
  configServiceData: {
    featureFlags: {},
  },
  itineraryDetails: {},
  lookup: {},
};

ItineraryResults.contextType = FormattedMessageContext;

function mapStateToProps(state) {
  return {
    accessKeyDetails: getAccessKey(state),
    appliedCurrency: selectCurrencyCode(state),
    appliedPriceType: selectPriceType(state),
    appliedSailors: selectSailors(state),
    cabinType: selectCabinType(state),
    celebrations: state?.celebrations,
    configServiceData: selectSettings(state),
    defaultPackages: selectDefaultPackages(state),
    filteredPackages: selectFilteredPackages(state),
    filteredSailings: selectFilteredSailings(state),
    filtersOptions: getFiltersOptions(state),
    isSuitableCabinType: selectIsSuitableCabinType(state),
    itineraryDetails: get(state, 'voyagePlanner.itineraryResults.itineraryDetails.data', {}),
    lookup: selectLookup(state),
    mgm: selectMgm(state),
    packages: selectMainPackages(state),
  };
}

function mapDispatchToProps(dispatch) {
  return {
    actions: bindActionCreators(
      {
        clearCabinCategoriesDataAction,
        clearInvoiceDataAction,
        exitReferredBookerFlow: exitReferredBookerFlowAction,
        payInFullPromoAppliedAction,
        resetTravelParty,
      },
      dispatch,
    ),
    dispatch,
  };
}

export default withIsDesktop(
  withRouter(withSearchParams(connect(mapStateToProps, mapDispatchToProps)(ItineraryResults))),
);
