import React from 'react';

import LocationImg from '@publicImages/svg/location.svg';

import Svg from '@/components/Svg';

const Location = () => (
  <span className="Icon Location">
    <Svg src={LocationImg} />
  </span>
);
export default Location;
