import { type JSXElementConstructor, type NamedExoticComponent, useCallback, useRef } from 'react';

import type { TRouteChangeListener } from './types';

import useOnRouteChange from './useOnRouteChange';

export type TWithOnRouteChange = {
  onRouteChange: (listener: TRouteChangeListener) => void;
};

const withOnRouteChange = <TProps extends TWithOnRouteChange>(Component: JSXElementConstructor<TProps>) => {
  const Wrapper = (props: TProps) => {
    const ref = useRef<TRouteChangeListener>();
    const onRouteChange = useCallback((listener: TRouteChangeListener) => {
      ref.current = listener;
    }, []);
    useOnRouteChange(ref.current!);
    return <Component {...props} onRouteChange={onRouteChange} />;
  };
  Wrapper.displayName = `withOnRouteChange<${(Component as NamedExoticComponent).displayName || Component.name}>`;
  return Wrapper;
};

export default withOnRouteChange;
