import type { TOptional } from '@/types/common';

import getOSName, { OSName } from './getOSName';

export enum ScreenReader {
  NVDA = 'NVDA',
  VoiceOver = 'VoiceOver',
}

const DEFAULT_SCREEN_READERS: Partial<Record<OSName, ScreenReader>> = {
  [OSName.MacOS]: ScreenReader.VoiceOver,
  [OSName.Windows]: ScreenReader.NVDA,
  [OSName.iOS]: ScreenReader.VoiceOver,
};

const getDefaultScreenReader = (): TOptional<ScreenReader> => {
  const osName = getOSName();
  if (osName) return DEFAULT_SCREEN_READERS[osName];
};

export default getDefaultScreenReader;
