import { useCallback } from 'react';

import useOpenable from '@/hooks/useOpenable';

const useActionStatus = (action?: () => void) => {
  const { close: closeStatus, isOpened: isStatusShown, open: show } = useOpenable();

  const actionWithStatus = useCallback(() => {
    show();
    action?.();
  }, [action]);

  return { actionWithStatus, closeStatus, isStatusShown };
};

export default useActionStatus;
