import PropTypes from 'prop-types';
import React from 'react';

import { connect } from 'react-redux';

import CabinTypeFilter from '@/components/AdvancedFilterRefinement/CabinTypeFilter';
import { selectMetaType } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { getFiltersOptions } from '@/ducks/filtersOptions';
import tracking from '@/ducks/pages/chooseVoyage/tracking';
import { FormattedMessageContext } from '@/helpers/formatted-message';

import './CabinTypeRefinement.scss';

class CabinTypeRefinement extends React.Component {
  onCabinTypeChange = (value) => {
    const { setCabinType, trackCabinType } = this.props;
    const { formatMessage } = this.context;
    let defaultVal = formatMessage({
      defaultMessage: 'Any — the best available',
      id: 'AdvancedFilter.cabintype.Default.value',
    });
    [defaultVal] = defaultVal;
    const newState = value?.trim() === defaultVal.trim() ? null : value;
    setCabinType(newState);
    trackCabinType(newState || defaultVal);
  };

  render() {
    const { appliedType, cabinTypes, closeFilterModal, isOpenFilterSection, toggleFilterState } = this.props;

    return (
      <CabinTypeFilter
        cabinTypes={cabinTypes}
        closeFilterModal={closeFilterModal}
        isOpenFilterSection={isOpenFilterSection}
        onCabinTypeChange={this.onCabinTypeChange}
        selectedType={appliedType}
        toggleFilterState={toggleFilterState}
      />
    );
  }
}

CabinTypeRefinement.propTypes = {
  appliedType: PropTypes.string,
  cabinTypes: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  closeFilterModal: PropTypes.func,
  setCabinType: PropTypes.func.isRequired,
  toggleView: PropTypes.bool,
  trackCabinType: PropTypes.func,
};
CabinTypeRefinement.defaultProps = {
  appliedType: null,
  closeFilterModal: () => {},
  toggleView: false,
  trackCabinType: () => {},
};

CabinTypeRefinement.contextType = FormattedMessageContext;

const mapStateToProps = (state) => ({
  appliedType: selectMetaType(state),
  cabinTypes: getFiltersOptions(state).cabinTypes,
  setCabinType: setFilter.metaType,
});

const mapDispatchToProps = {
  trackCabinType: tracking.trackCabinType,
};

export default connect(mapStateToProps, mapDispatchToProps)(CabinTypeRefinement);
