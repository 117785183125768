import PropTypes from 'prop-types';
import React from 'react';

import isEmpty from 'lodash/isEmpty';
import { connect } from 'react-redux';

import { selectLookup } from '@/ducks/common/selectors';
import { selectShipsLabel } from '@/ducks/filters/labels/ships';
import { selectShipList } from '@/ducks/filters/selectors';
import { setFilter } from '@/ducks/filters/setters';
import { selectMainSailings } from '@/ducks/pages/chooseVoyage/selectors';
import { FormattedMessage, FormattedMessageContext } from '@/helpers/formatted-message';

import { FormCheck } from '../elements';
import AdvancedFilterHOC from './AdvancedFilterHOC';
import BaseFilter from './BaseFilter';

class ShipFilter extends BaseFilter {
  onSelection = (ship) => {
    const { selectedships } = this.props;
    const selected = selectedships?.find((currShip) => currShip.id === ship.id);

    if (!selected) {
      setFilter.ships([...selectedships, ship]);
      return;
    }
    const shipList = selectedships.filter((secShip) => secShip.id !== ship.id);

    setFilter.ships(shipList);
  };

  render() {
    const { closeFilterModal, isOpenFilterSection, label, mainSailings, metaShipList, selectedships } = this.props;
    const { formatMessage } = this.context;
    const shipText = formatMessage({
      defaultMessage: 'Ship',
      id: 'AdvancedFilter.Ship',
    });
    return (
      <div className="AF__row ShipFilter__main">
        <AdvancedFilterHOC
          closeFilterModal={closeFilterModal}
          contentName="ship-filter"
          defaultValue={label}
          filterName={shipText}
          isOpen={isOpenFilterSection}
          toggleFilterSectionState={this.toggleFilterSectionState}
        >
          <div className="action ship-list">
            {!isEmpty(metaShipList) &&
              metaShipList.map((ship) => (
                <FormCheck
                  checked={!isEmpty(selectedships?.find((selecShip) => selecShip?.id === ship?.id))}
                  disabled={!mainSailings.find((sailing) => sailing?.ship?.code === ship?.id)}
                  id={`ship_${ship?.id}`}
                  inputTabIndex={0}
                  key={ship?.id}
                  name={`ship_${ship?.id}`}
                  onChange={() => {
                    this.onSelection(ship);
                  }}
                  role="presentation"
                  tabIndex={-1}
                  type="checkbox"
                >
                  <p className="name">{ship?.name}</p>
                  <p className="year">{ship?.inaugurationDate}</p>
                </FormCheck>
              ))}
          </div>
          <p className="info">
            <FormattedMessage
              defaultMessage="All our ladyships are of the same luxury design; and adults-only. "
              id="AdvancedFilter.ship.info"
            />
          </p>
        </AdvancedFilterHOC>
      </div>
    );
  }
}

ShipFilter.contextType = FormattedMessageContext;

ShipFilter.propTypes = {
  closeFilterModal: PropTypes.func.isRequired,
  isOpenFilterSection: PropTypes.bool,
  label: PropTypes.string.isRequired,
  mainSailings: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  metaShipList: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedships: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
};

ShipFilter.defaultProps = {
  isOpenFilterSection: false,
};

const mapStateToProps = (state) => ({
  label: selectShipsLabel(state),
  mainSailings: selectMainSailings(state),
  metaShipList: selectLookup(state)?.ships ?? [],
  selectedships: selectShipList(state),
});

export default connect(mapStateToProps)(ShipFilter);
