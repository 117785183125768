import compact from 'lodash/compact';

import type { FilteredPackage } from '@/infra/types/voyageInfo/package';

import { MAX_COUNT_DATES_IN_PACKAGE_CARD } from '@/components/ChooseVoyage/PackageCardDetails/constants';

export const getSailingsToDisplay = (packageData?: FilteredPackage) => {
  const sortedSailings = packageData?.sortedSailings || [];
  const sailingLength = packageData?.sortedSailings?.length ?? 0;
  const shouldDisplayBestPriceSailing = !!packageData?.bestRate && sailingLength > 1;
  const bestPriceSailing = packageData?.bestPriceSailing;

  let sortedSailingsToDisplay = sortedSailings;
  if (shouldDisplayBestPriceSailing && bestPriceSailing) {
    sortedSailingsToDisplay = sortedSailings.filter((sailingData) => sailingData.id !== bestPriceSailing.id);
  }
  const noOfSailingsToDisplay = shouldDisplayBestPriceSailing ? 2 : MAX_COUNT_DATES_IN_PACKAGE_CARD;

  const sailingsToDisplay = compact([
    ...(shouldDisplayBestPriceSailing ? [bestPriceSailing] : []),
    ...sortedSailingsToDisplay.slice(0, noOfSailingsToDisplay),
  ]);
  return {
    bestPriceSailing,
    sailingsToDisplay,
    shouldDisplayBestPriceSailing,
  };
};
