import React from 'react';

import CabinImg from '@publicImages/svg/cabin-door.svg';

import Svg from '@/components/Svg';

const CabinDoor = () => (
  <span className="Icon Cabin">
    <Svg src={CabinImg} />
  </span>
);

export default CabinDoor;
