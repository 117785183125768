import { getAuthFromStorage } from '@/helpers/auth/core';
import tagmanager from '@/tagmanager';
import getPage from '@/tagmanager/routesMapping';

export const trackSailorId = () => {
  const auth = getAuthFromStorage();
  if (auth) {
    tagmanager.trackData({
      sailor_id: auth.userId, // unique sailor identifier
    });
  }
};

export const trackResetFilters = (filterName: string) => {
  const location = getPage();
  tagmanager.trackEvent({
    event: 'GTMevent',
    eventAction: 'link-click',
    eventCategory: `VV-${location}`,
    eventLabel: `reset|${filterName}`,
  });
};

export const setTrackingDestinationFilter = (_isWholeSelected) => (_dispatch, _getState) => {
  // TODO
  // const page = getPage();
  // const sailings = getFilteredSailings(getState());
  // dispatch({ payload: sailings, type: CHOOSE_VOYAGE_SEARCH_SUCCESS });
  // const itineraryFilters = getItineraryFilters(getState());
  // const trackingLabel = [];
  // if (itineraryFilters) {
  //   sortRegions(itineraryFilters).map((data) => {
  //     const { appliedPackages, appliedPorts, id, name } = data;
  //     if (appliedPackages.length > 0 && appliedPorts.length <= 0) {
  //       trackingLabel.push(
  //         `${kebabCase(name)}(${isWholeSelected(id, DestinationType.ITINERARIES) ? 'all' : appliedPackages.length})`,
  //       );
  //     } else if (appliedPorts.length > 0 && appliedPackages.length <= 0) {
  //       trackingLabel.push(
  //         `${kebabCase(name)}(${isWholeSelected(id, DestinationType.PORTS_OF_CALL) ? 'all' : appliedPorts.length})`,
  //       );
  //     }
  //     return null;
  //   });
  // }
  // if (trackingLabel.length > 0) {
  //   tagmanager.trackEvent({
  //     event: 'GTMevent',
  //     eventAction: 'filter-applied',
  //     eventCategory: `VV-${page}`,
  //     eventLabel: `region|${trackingLabel.join('-')}`,
  //     number_of_filtering_results: sailings.length,
  //   });
  // } else {
  //   tagmanager.trackEvent({
  //     event: 'GTMevent',
  //     eventAction: 'filter-applied',
  //     eventCategory: `VV-${page}`,
  //     eventLabel: 'region',
  //     number_of_filtering_results: sailings.length,
  //   });
  // }
};
