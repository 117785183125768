import Image from 'next/image';
import { useCallback } from 'react';

import cn from 'classnames';

import ArrowLeft from '@/components/Icon/ArrowLeft';
import CaretRight from '@/components/Icon/CaretRight';
import UIResource from '@/components/UIResource';
import { FormCheck } from '@/components/elements';

import { type DestinationRegion as Item } from './types';

type DestinationItemProps = {
  checkedIds: string[];
  disableSwitchToRegionOnSelect?: boolean;
  focused?: boolean;
  id: string;
  isWholeChecked: boolean;
  onCheck: (id: string) => void;
  region: Item;
  selected: boolean;
};

const DestinationRegion = ({
  checkedIds,
  disableSwitchToRegionOnSelect,
  id,
  isWholeChecked,
  onCheck,
  region,
  selected,
}: DestinationItemProps) => {
  const isChecked = checkedIds.length > 0;
  const labelId = `${id}_label`;
  const announceLabel = `${region.label}, checkbox`;

  const checkboxOnClickHandler = useCallback(
    (e) => {
      if (disableSwitchToRegionOnSelect) {
        e.stopPropagation();
      }
    },
    [disableSwitchToRegionOnSelect],
  );

  return (
    <div aria-labelledby={labelId} className={cn('filterRegionsListItem', { _checked: isChecked && !isWholeChecked })}>
      <FormCheck
        checked={isWholeChecked}
        id={`select-all-${id}`}
        labelProps={{ onClick: checkboxOnClickHandler }}
        onChange={() => onCheck(region.id)}
        onClick={checkboxOnClickHandler}
        tabIndex={-1}
        type="checkbox"
        value={region.id}
      />

      <div className="regionRow" id={`region-row-${id}`}>
        <div aria-hidden="true" className="image">
          {region.image && <Image alt="" height="100" src={region.image.src} width="100" />}

          <div
            className={cn('text_description', {
              active: isChecked,
            })}
          >
            <p className="text">
              {isWholeChecked ? (
                <UIResource id="DestinationRefinement.allSelected" />
              ) : isChecked ? (
                <UIResource id="DestinationRefinement.selected" values={{ count: checkedIds.length }} />
              ) : null}
            </p>
          </div>
        </div>

        <div className="description" id={labelId}>
          <div className="name">{region.label}</div>
          <div className="title">{region.subLabel}</div>
          {/* key prop forces recreation of node for safari Voiceover to properly read contents */}
          <div className="sr-only" key={checkedIds.length}>
            {', '}
            {isWholeChecked && `All checked, ${announceLabel}`}
            {!isWholeChecked &&
              (isChecked ? `${checkedIds.length} checked, ${announceLabel}` : `Not checked, ${announceLabel}`)}
          </div>
        </div>

        <button aria-hidden="true" className="DestinationIcon --button" tabIndex={-1}>
          <span className="">
            <span className="caretRight">
              <CaretRight />
            </span>

            <span className="arrowRight">
              <ArrowLeft />
            </span>
          </span>
        </button>

        <div
          className={cn('horizontalLine', {
            active: selected,
          })}
        ></div>
      </div>
    </div>
  );
};

export default DestinationRegion;
