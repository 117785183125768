'use client';

import Search from '@publicImages/svg/search.svg?icon';

import { Skeleton } from '@/components/Skeleton';
import { SKELETON_COLOR_SCHEMES } from '@/components/Skeleton/constants';
import Button from '@/components/elements/Button';
import { useUIResource } from '@/ducks/common/resources';

import classes from './Card.module.scss';

const styles = SKELETON_COLOR_SCHEMES['gray-216-gray-410'];

type Props = {
  isLoading?: boolean;
  onClick?: (region: string) => void;
  price?: React.ReactNode;
  priceOld?: React.ReactNode;
  priceType?: React.ReactNode;
  title?: React.ReactNode;
  url?: string;
};

export const QuickSearchCard = ({ isLoading, onClick, price, priceOld, priceType, title, url }: Props) => {
  const from = useUIResource('QuickSearch.fromPrice');
  const onCardClick = () => onClick?.(String(title));

  return (
    <a className={classes.root} href={url} onClick={onCardClick} onKeyUp={onCardClick} tabIndex={0}>
      <div className={classes.description}>
        <p className={classes.title}>{isLoading ? <Skeleton {...styles} rounded width={100} /> : title}</p>
        <div className={classes.prices}>
          <span className={classes.new}>
            {isLoading ? <Skeleton {...styles} rounded width={50} /> : `${from} ${price}`}
          </span>
          <span className={classes.old}>{isLoading ? <Skeleton {...styles} rounded width={50} /> : priceOld}</span>
        </div>
        <p className={classes.priceType}>{isLoading ? <Skeleton {...styles} rounded width={70} /> : priceType}</p>
      </div>
      <div aria-hidden className={classes.cta}>
        <Button asChild variant="primary">
          <Search />
        </Button>
      </div>
    </a>
  );
};
