import React, { useMemo } from 'react';

import { selectAccessible } from '@/ducks/filters/selectors';
import { FILTER_KEY } from '@/ducks/filters/types';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const AccessibleFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const accessible = useAppSelector(selectAccessible);

  const label = useMemo(() => {
    return accessible === true ? 'Accessible' : null;
  }, [accessible]);

  return label ? (
    <SelectedFilterChip filterKeys={FILTER_KEY.accessible} label={label} onOpenFilterModal={onOpenFilterModal} />
  ) : null;
};

export default AccessibleFilterChip;
