import type { AppDispatch } from '@/store';

import { resetFmFlow } from '@/ducks/fm/reset';
import {
  MNVV_AGENCY_ID_KEY,
  MNVV_AGENT_ID_KEY,
  MNVV_CHANNEL_ID_KEY,
  MNVV_RESERVATION_NUMBER_KEY,
} from '@/ducks/mnvv/constants';
import { removeSearchParamsExcept } from '@/ducks/routes/history';

export const resetMnvvSearchCriteria = () => (_dispatch: AppDispatch) => {
  removeSearchParamsExcept([
    MNVV_RESERVATION_NUMBER_KEY,
    MNVV_CHANNEL_ID_KEY,
    MNVV_AGENT_ID_KEY,
    MNVV_AGENCY_ID_KEY,
    'bookingChannel',
  ]);
};

export const resetMnvvFlow = () => (dispatch: AppDispatch) => {
  dispatch(resetFmFlow([MNVV_RESERVATION_NUMBER_KEY, MNVV_CHANNEL_ID_KEY]));
};
