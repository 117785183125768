import { JL } from 'jsnlog';

import { GET_BLACK_FRIDAY_PROMOTIONS, useGQLQuerySSR } from '@/helpers/api/graphql';

export const useGetBFResources = () => {
  const response = useGQLQuerySSR(GET_BLACK_FRIDAY_PROMOTIONS, { errorPolicy: 'all' });
  const promotionBanner = response?.data?.lookup?.promotionBanner || {};
  const graphQLErrors = response?.error?.graphQLErrors ?? [];

  if (graphQLErrors && graphQLErrors.length > 0) {
    graphQLErrors.forEach((error) => {
      const messageDetail = {
        message: 'Black Friday Promo banners fetch failure',
        messageCode: '9001',
        severity: 'ERROR',
      };
      JL('BV').error({ message: error, messageDetail });
      console.error(graphQLErrors);
    });
  }

  return promotionBanner;
};
