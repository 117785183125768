import { type HTMLAttributes, type ReactNode, useEffect, useId } from 'react';

import cn from 'classnames';

import Alert from '@publicImages/svg/alert.svg?static';
import Exclamation from '@publicImages/svg/exclamation.svg?static';
import Tick from '@publicImages/svg/tick.svg?static';

import { WarningType } from '@/features/warnings/types';
import { scrollIntoViewAsync } from '@/helpers/scrollIntoView';

import styles from './WarningBoxView.module.scss';

const ICONS = {
  [WarningType.ERROR]: Alert,
  [WarningType.INFO]: Tick,
  [WarningType.WARNING]: Exclamation,
};

type TProps = HTMLAttributes<HTMLDivElement> & {
  scrollToBox?: boolean;
  text?: ReactNode;
  title: ReactNode;
  titleIcon?: ReactNode;
  type?: WarningType;
};

const WarningBoxView = ({ className, scrollToBox, text, title, titleIcon, type, ...restRootProps }: TProps) => {
  const safeType = type ?? WarningType.WARNING;
  const Icon = ICONS[safeType];
  const warningContentId = useId();

  useEffect(() => {
    if (scrollToBox) {
      scrollIntoViewAsync(`#${CSS.escape(warningContentId)}`);
    }
  }, [scrollToBox, warningContentId]);

  return (
    <div
      {...restRootProps}
      aria-labelledby={warningContentId}
      className={cn(styles.root, className, `_${safeType}`)}
      role="alert"
      tabIndex={0}
    >
      <span aria-hidden className={cn(styles.rootIcon)}>
        <Icon />
      </span>
      <h3 className={cn(styles.title)} id={warningContentId}>
        {title}
        {titleIcon}

        {/* Hack, VO + Safari ignores aria-describedby for alert */}
        <span className="sr-only">{text}</span>
      </h3>
      <div aria-hidden className={cn(styles.text)}>
        {text}
      </div>
    </div>
  );
};

export default WarningBoxView;
