'use client';

import { ReadonlyURLSearchParams, usePathname, useSearchParams } from 'next/navigation';
import React, { useCallback } from 'react';

import type { TUIResourceId } from '@/ducks/common/resources';

import UIResource from '@/components/UIResource';
import { env } from '@/environment';
import formatMoney from '@/helpers/util/formatMoney';
import tagmanager from '@/tagmanager';

import { useIsPromoActive } from '../lib/useIsPromoActive';
import { QuickSearchCard } from './Card';
import { useQuickSearchItems } from './model/hooks';
import { QuickSearchCardsSkeleton } from './skeleton';

import classes from './QuickSearchCards.module.scss';

const mergeURLWithPreviousData = (pathname: string, url: string, params: ReadonlyURLSearchParams | null) => {
  const newParams = new URLSearchParams(url.split('?')[1]);
  const paramsToPreserve = [
    'reservationNumber',
    'channelID',
    'agencyId',
    'agentId',
    'selectedPackages',
    'selectedRegionsIds',
    'currencyCode',
  ];

  paramsToPreserve.forEach((key) => {
    params?.getAll(key).forEach((value) => newParams.append(key, value));
  });

  return `${env.CONTEXT}${pathname}?${newParams}`;
};

const QuickSearchCardsComponent = () => {
  const { isLoaded, quickSearchItems } = useQuickSearchItems();

  const pathname = usePathname();
  const params = useSearchParams();

  const onCardClick = useCallback((region: string) => {
    // TODO: should we change tracker topic?
    tagmanager.tracker.blackFriday.onAdvertCardClick(region);
  }, []);

  return (
    <div className={classes.draggable}>
      <div className={classes.row}>
        <div className={classes.container}>
          {!isLoaded ? (
            <QuickSearchCardsSkeleton />
          ) : (
            quickSearchItems?.map(({ currencyCode, name, position, price, priceOld, url }) => {
              const formattedPrice = formatMoney(price, currencyCode);
              const formattedOldPrice = priceOld ? formatMoney(priceOld, currencyCode) : '';

              return (
                <QuickSearchCard
                  key={name}
                  onClick={onCardClick}
                  price={formattedPrice!}
                  priceOld={formattedOldPrice}
                  priceType="per cabin"
                  title={<UIResource id={`QuickSearchPosition${position}.heading` as TUIResourceId} />}
                  url={mergeURLWithPreviousData(pathname, url, params)}
                />
              );
            })
          )}
        </div>
      </div>
    </div>
  );
};

export const QuickSearchCards = () => {
  const isActive = useIsPromoActive('quickSearch');
  return isActive && <QuickSearchCardsComponent />;
};
