import { selectSailingData } from '@/ducks/voyageInfo/sailing/selectors';
import { useAppSelector } from '@/store';

import { PromotionTimer } from './Timer';
import { type MerchandisingComponents, useIsPromoActive } from './lib/useIsPromoActive';

// Using dynamic leads to CLS
const MAP_TO_COMPONENTS = {
  countdownClock: PromotionTimer,
} satisfies Partial<Record<MerchandisingComponents, React.ComponentType>>;

type BannerTypes = keyof typeof MAP_TO_COMPONENTS;
type ExtractProps<T> = T extends React.FC<infer P> ? P : never;
type ComponentPropsMap = {
  [Type in BannerTypes]: ExtractProps<(typeof MAP_TO_COMPONENTS)[Type]>;
};

type MerchandisingBannerProps = {
  [Type in BannerTypes]: {
    type: Type;
  } & ComponentPropsMap[Type];
}[BannerTypes];

export const MerchandisingBanner = ({ type, ...props }: MerchandisingBannerProps) => {
  const sailingData = useAppSelector(selectSailingData);
  const isPromoActive = useIsPromoActive(type, sailingData);

  if (!isPromoActive) {
    return null;
  }

  const Component = MAP_TO_COMPONENTS[type];

  return <Component {...props} />;
};
