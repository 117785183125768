import { createSelector } from '@reduxjs/toolkit';

import { type TUIResourceId, combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import getSymbolFromCurrenciesData from '@/helpers/util/currency/currencySymbols';
import { FiltersPriceType } from '@/infra/types/common/filters';

import { selectDefaultPriceRange } from '../defaultValuesSelectors';
import { selectCurrencyCode, selectMaxPrice, selectMinPrice, selectPriceType } from '../selectors';

export const selectPriceLabel = createSelector(
  selectDefaultPriceRange,
  selectMaxPrice,
  selectMinPrice,
  selectPriceType,
  selectCurrencyCode,
  selectResources,
  (defaultPriceRange, maxPrice, minPrice, priceType, currencyCode, resources) => {
    let id: TUIResourceId = 'AdvancedFilter.PriceRange.label.perSailor';
    if (priceType === FiltersPriceType.perCabin) {
      id = 'AdvancedFilter.PriceRange.label.perCabin';
    } else if (priceType === FiltersPriceType.sailorPerNight) {
      id = 'AdvancedFilter.PriceRange.label.SailorPerNight';
    }

    const updatedMaxPrice = maxPrice !== null ? maxPrice : defaultPriceRange.maxPrice;
    const updatedMinPrice = minPrice !== null ? minPrice : defaultPriceRange.minPrice;

    return combineUIResource(resources, id, {
      m: `${getSymbolFromCurrenciesData(currencyCode)}${Math.floor(updatedMaxPrice || 0)}`,
      n: `${getSymbolFromCurrenciesData(currencyCode)}${Math.floor(updatedMinPrice || 0)}`,
    });
  },
);
