import React from 'react';

import { Skeleton } from '@/components/Skeleton';

const AdvancedSelectionSkeleton = () => {
  return (
    <section className="AdvancedSelection AdvancedSelection__Skeleton">
      <div className="AdvancedSelection__text">
        <Skeleton inline width={80} />
        <Skeleton inline width={180} />
        <Skeleton inline width={200} />
      </div>

      <div className="AdvancedSelection__action">
        <div className="sortDropdown">
          <Skeleton height={40} width={270} />
        </div>
        <div className="sortDropdown">
          <Skeleton height={40} width={179} />
        </div>
      </div>
    </section>
  );
};

export default AdvancedSelectionSkeleton;
