import type { TOptional } from '@/types';

import { isServerSide } from '@/helpers/isServerSide';
import { getMobileOperatingSystem } from '@/helpers/util/misc';

/* The 3rd-party "react-remove-scroll-bar" module sets the margins when scrolling is locked. These margins are
 calculated based on the window.innerWidth parameter (https://github.com/theKashey/react-remove-scroll-bar/blob/master/src/utils.ts).
 But under some conditions in android, this parameter has the wrong value. This is where it gets fixed
 */

class ReactRemoveScrollBarAndroidFix {
  readonly CLASS_NAME = '_react-remove-scroll-bar-android-fix';
  private isApplied: TOptional<boolean>;
  private modifiers: string[];

  static tryCreate(modifierName?: string): TOptional<ReactRemoveScrollBarAndroidFix> {
    if (!isServerSide() && getMobileOperatingSystem() === 'android') {
      return new ReactRemoveScrollBarAndroidFix(modifierName);
    }
  }

  apply() {
    if (this.isApplicable()) {
      document.body.classList.add(...this.modifiers);
      this.isApplied = true;
    }
  }

  reset() {
    if (this.isApplied) {
      document.body.classList.remove(...this.modifiers);
      this.isApplied = false;
    }
  }

  private constructor(modifierName?: string) {
    this.modifiers = [this.CLASS_NAME];
    if (modifierName) this.modifiers.push(`_${modifierName}`);
  }

  private isApplicable() {
    return window.outerWidth < window.innerWidth || window.screen.availWidth < window.innerWidth;
  }
}

export default ReactRemoveScrollBarAndroidFix;
