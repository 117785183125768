import PropTypes from 'prop-types';
import React from 'react';

import useOnKeyDown from '@/hooks/useOnKeyDown';

import Flyout from '../Flyout';
import ArrowLeft from '../Icon/ArrowLeft';
import CloseWithoutBackground from '../Icon/ChooseVoyage/CloseWithoutBackground';

const AdvancedFilterMobileHOC = ({
  children,
  closeFilterModal,
  contentName,
  defaultValue,
  isOpen,
  onTraverseBack,
  title,
}) => {
  const onTraverseBackKeyDown = useOnKeyDown(onTraverseBack);

  return (
    <>
      {isOpen && (
        <Flyout
          appear={false}
          className="AdvancedFilterHOC_mobile"
          delay={0}
          direction="right"
          hideCrossButton
          onDismiss={closeFilterModal}
          open={isOpen}
        >
          <div className="row">
            <div className="col col2">
              <div className="header">
                <div className="title_section">
                  <div onClick={onTraverseBack} onKeyDown={onTraverseBackKeyDown} role="button" tabIndex={0}>
                    <ArrowLeft />
                  </div>
                  <p className="title">{title}</p>
                  <button aria-label="Close" className="close-mobile" onClick={closeFilterModal} type="button">
                    <CloseWithoutBackground />
                  </button>
                </div>
                <p className="value">
                  <span>{defaultValue}</span>
                </p>
              </div>

              <div className={`content ${contentName}`}>{children}</div>
            </div>
          </div>
        </Flyout>
      )}
    </>
  );
};

AdvancedFilterMobileHOC.propTypes = {
  children: PropTypes.oneOfType([PropTypes.objectOf]).isRequired,
  closeFilterModal: PropTypes.func.isRequired,
  contentName: PropTypes.string,
  defaultValue: PropTypes.string.isRequired,
  isOpen: PropTypes.bool.isRequired,
  onTraverseBack: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
};

AdvancedFilterMobileHOC.defaultProps = {
  contentName: '',
};

export default AdvancedFilterMobileHOC;
