import { PropTypes } from 'prop-types';
import React from 'react';

import CloseImg from '@publicImages/svg/circle-close.svg';

import Svg from '@/components/Svg';

const CircleClose = ({ id }) => (
  <span className="Icon Close" id={id}>
    <Svg src={CloseImg} />
  </span>
);

CircleClose.propTypes = {
  id: PropTypes.string,
};

CircleClose.defaultProps = {
  id: 'close',
};

export default CircleClose;
