import type { ApolloClient } from '@apollo/client';

import isEmpty from 'lodash/isEmpty';
import sortBy from 'lodash/sortBy';

import type { ResourcesSlice } from '@/ducks/common/resources';
import type { TCurrencyCode } from '@/infra/types/common';
import type { AppDispatch, AppGetState } from '@/store';

import { selectResources } from '@/ducks/common/selectors';
import { QUICK_SEARCH_ITEMS, type QuickSearchItemsResponse } from '@/helpers/api/graphql/queries/quick-search-items';
import { AppAuthenticator, TokenType } from '@/helpers/api/tokens';
import { log } from '@/helpers/util/logger';
import { FiltersPriceType } from '@/infra/types/common/filters';

import { addData, resetData, updateData } from './reducer';
import { selectQuickSearchItemsIsLoaded } from './selectors';
import {
  type CmsQuickSearchItemsQualifiers,
  type GraphqlQuickSearchItemsQualifier,
  type QuickSearchItems,
} from './types';

const getCmsSearchQualifiers = (resources: ResourcesSlice) => {
  let data: CmsQuickSearchItemsQualifiers[];
  try {
    data = JSON.parse(resources[`QuickSearch.JSON`] as string);
  } catch {
    log(`Unable to parse JSON from CMS value QuickSearch.JSON`);
    return [];
  }

  return data.filter(({ position }) => !isEmpty(resources[`QuickSearchPosition${position}.heading`]));
};

const getSearchQualifiers = (data: CmsQuickSearchItemsQualifiers[]) => {
  return data.reduce<GraphqlQuickSearchItemsQualifier[]>((qualifiers, qualifier) => {
    return [
      ...qualifiers,
      {
        name: qualifier.name,
        preferences: (qualifier.externalRegionIds || qualifier.selectedRegionsIds).map((regionCode) => ({
          regionCode,
        })),
        sailingDateRange: [{ end: qualifier.sailingToDate, start: qualifier.sailingFromDate }],
      },
    ];
  }, []);
};

export const fetchQuickSearchItems = async (
  apolloClient: ApolloClient<unknown>,
  resources: ResourcesSlice,
  currencyCode: TCurrencyCode = 'USD',
  sailors: number = 2,
): Promise<QuickSearchItems> => {
  const cmsQualifiers = getCmsSearchQualifiers(resources);

  const qualifiers = cmsQualifiers.reduce(
    (data, item) => ({ ...data, [item.name]: item }),
    {} as Record<string, CmsQuickSearchItemsQualifiers>,
  );

  const guestAccessToken = (await AppAuthenticator.getInstance().getFreshAccessToken({
    tokenType: TokenType.guest,
  }))!;

  try {
    const data = (
      await apolloClient.query({
        context: { headers: { authorization: `bearer ${guestAccessToken}` } },
        query: QUICK_SEARCH_ITEMS,
        variables: {
          value: {
            currencyCode,
            guestCounts: [{ ageCategory: 'Adult', count: sailors }],
            searchQualifiers: getSearchQualifiers(cmsQualifiers),
          },
        },
      })
    ).data as QuickSearchItemsResponse;

    const items = data?.quickSearchItems?.map(({ currencyCode, discount, minPrice, name }) => {
      let price = Math.floor(minPrice);
      const priceOld = price;

      if (price > 0 && discount > 0) {
        price = Math.floor(price - discount);
      }

      const params = new URLSearchParams({
        dateFrom: qualifiers[name]?.sailingFromDate ?? '',
        dateTo: qualifiers[name]?.sailingToDate ?? '',
        priceType: FiltersPriceType.perCabin,
      });

      qualifiers[name]!.selectedRegionsIds.forEach((id) => params.append('selectedRegions', id));

      const position = qualifiers[name]!.position;

      return {
        currencyCode,
        name,
        position,
        price,
        priceOld: price === priceOld ? undefined : priceOld,
        url: `/voyage-planner/find-a-voyage?${params}`,
      };
    });

    return sortBy(items, 'position');
  } catch (e) {
    log(e);
    return [];
  }
};

export const updateQuickSearchItemsAction =
  (apolloClient: ApolloClient<unknown>, currencyCode: TCurrencyCode = 'USD', sailors: number = 2) =>
  async (dispatch: AppDispatch, getState: AppGetState) => {
    const state = getState();

    const resources = selectResources(state);
    const isLoaded = selectQuickSearchItemsIsLoaded(currencyCode)(state);

    if (isLoaded) {
      return;
    }

    dispatch(addData({ currencyCode, sailors }));

    try {
      dispatch(
        updateData({
          currencyCode,
          items: await fetchQuickSearchItems(apolloClient, resources, currencyCode, sailors),
          sailors,
        }),
      );
    } catch (e) {
      log(e);
      dispatch(resetData({ currencyCode, sailors }));
    }
  };
