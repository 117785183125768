import Button from '@/components/elements/Button';

type Props = {
  description: React.ReactNode;
  emoji: React.ReactNode;
  firstButtonText: React.ReactNode;
  onFirstButtonClick: () => void;
  onSecondButtonClick: () => void;
  secondButtonText: React.ReactNode;
};

const TrueZeroResults = ({
  description,
  emoji,
  firstButtonText,
  onFirstButtonClick,
  onSecondButtonClick,
  secondButtonText,
}: Props) => (
  <>
    <p className="ZeroResultsTrueEmoji">{emoji}</p>
    <p className="ZeroResultsTrueDescription">{description}</p>
    <p className="ZeroResultsReset">
      <Button onClick={onFirstButtonClick} variant="secondary">
        {firstButtonText}
      </Button>
    </p>
    <p className="ZeroResultsReset">
      <Button onClick={onSecondButtonClick} variant="link">
        {secondButtonText}
      </Button>
    </p>
  </>
);

export default TrueZeroResults;
