export const callInSequence = (func: CallableFunction, delay?: number) => {
  const promises: Promise<unknown>[] = [];
  return async (...args: any[]) => {
    await Promise.all(promises);
    const promise = new Promise((resolve, reject) => {
      setTimeout(async () => {
        try {
          resolve(await func(...args));
        } catch (e) {
          reject(e);
        }
      }, delay || 0);
    });

    promises.push(promise);

    return promise.then((result) => {
      promises.shift();
      return result;
    });
  };
};
