import { type FC, useMemo } from 'react';

import classNames from 'classnames';

import CaretRight from '@/components/Icon/CaretRight';
import { getFilterAgent } from '@/ducks/fm/getters';
import useScrollIntersection from '@/hooks/useScrollIntersection';

import './ArrowButtonToggleAnimated.style.scss';

type ArrowButtonToggleAnimatedProps = {
  className?: string;
  direction: 'down' | 'up';
};

export const ArrowButtonToggleAnimated: FC<ArrowButtonToggleAnimatedProps> = ({ className = '', direction }) => {
  const baseClassName = 'ArrowButtonToggleAnimated';

  const isFMLinkShown = useMemo(() => getFilterAgent()?.data?.isValid || false, []);
  const isScrolled = useScrollIntersection(
    { prop: 'bottom', selector: '.VoyagePlanner__content' },
    { prop: 'bottom', selector: '.ProgressBar' },
  );
  const classes = classNames({
    [`${baseClassName}__${direction}`]: !!direction,
    [baseClassName]: true,
    [className]: !!className,
    isFMLinkShown,
    isScrolled,
  });

  return (
    <span className={classes}>
      <CaretRight className={`${baseClassName}__caret`} />
    </span>
  );
};

ArrowButtonToggleAnimated.displayName = 'ArrowButtonToggleAnimated';
