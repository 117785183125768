import { useSearchParams } from 'next/navigation';
import React from 'react';

import { selectSailorsLabel } from '@/ducks/filters/labels/sailors';
import { FILTER_KEY } from '@/ducks/filters/types';
import { checkIfFilterOverridden } from '@/ducks/filters/utils';
import { useAppSelector } from '@/store';

import SelectedFilterChip from '../index';

const TravelPartyFilterChip = ({ onOpenFilterModal }: { onOpenFilterModal: CallableFunction }) => {
  const label = useAppSelector(selectSailorsLabel);
  const searchParams = useSearchParams();

  return label && checkIfFilterOverridden(FILTER_KEY.sailors, searchParams) ? (
    <SelectedFilterChip
      filterKeys={[FILTER_KEY.sailors, FILTER_KEY.cabins]}
      label={label}
      onOpenFilterModal={onOpenFilterModal}
    />
  ) : null;
};

export default TravelPartyFilterChip;
