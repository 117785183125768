import { createSelector } from '@reduxjs/toolkit';
import find from 'lodash/find';
import isEmpty from 'lodash/isEmpty';

import type { TFiltersOptions } from '@/infra/types/common/filters';

import { combineUIResource } from '@/ducks/common/resources';
import { selectResources } from '@/ducks/common/selectors';
import { selectMetaType } from '@/ducks/filters/selectors';
import { getFiltersOptions } from '@/ducks/filtersOptions';

const getLabelforCabinType = (cabinTypes: TFiltersOptions['cabinTypes'], selectedType: string) => {
  const cabinType = find(cabinTypes, { code: selectedType });
  let label = '';
  if (selectedType) {
    label = isEmpty(cabinType) ? selectedType : cabinType?.name;
  }
  return label;
};

export const selectMetaTypeLabel = createSelector(
  selectMetaType,
  getFiltersOptions,
  selectResources,
  (metaType, filtersOptions, resources) => {
    return (
      getLabelforCabinType(filtersOptions.cabinTypes, metaType) ||
      combineUIResource(resources, 'AdvancedFilter.cabintype.Default.value')
    );
  },
);
