import React from 'react';

import SailorsIconImg from '@publicImages/svg/sailors.svg';

import Svg from '@/components/Svg';

const Sailors = () => (
  <span className="Icon Sailors">
    <Svg src={SailorsIconImg} />
  </span>
);

export default Sailors;
