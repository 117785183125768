import { useEffect } from 'react';

import { selectConfigServiceFeatureFlags, selectSettings } from '@/ducks/common/settings';
import { selectCurrencyCode } from '@/ducks/filters/selectors';
import { loadUplift } from '@/infra/uplift/uplift';
import { useAppSelector } from '@/store';

export const useInitUplift = () => {
  const isUpliftEnabled = Boolean(useAppSelector(selectConfigServiceFeatureFlags)?.uplift);
  const upliftConfiguration = useAppSelector(selectSettings)?.uplift;
  const currencyCode = useAppSelector(selectCurrencyCode);

  useEffect(() => {
    loadUplift({ config: upliftConfiguration, currencyCode, isEnabled: isUpliftEnabled });
  }, [isUpliftEnabled, upliftConfiguration, currencyCode]);
};
